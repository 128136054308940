<template>
  <section class="game_body opponent-panel">
    <div class="main-screen">
      <div
        class="results-box"
        v-if="
          this.battleinfo.status === 'MATCH_NOT_FOUND' ||
            this.battleinfo.status === 'COMPLETED'
        "
      >
        <p>{{ this.$data.battleinfo.successMessage }}</p>
        <span @click="goBack" class="go-back-battle"
          >{{ $t('Tryagain') }}
        </span>
      </div>
      <div class="inner-container">
        <div
          class="opponent-screenbx"
          v-if="
            this.battleinfo.status === 'SEARCHING' ||
              this.battleinfo.status === 'CONNECTED' ||
              this.battleinfo.status === 'PLAYING'
          "
        >
          <!-- <span class="go-back" @click="goBack()"><img src="/static/images/go-back-icon.png"></span>
  <span class="close-opponent">X</span> -->
          <div class="inner-opponentbx">
            <h2>{{ this.$data.battleinfo.status }}</h2>
            <div class="opponent-content">
              <div class="title-block">
                <h3>
                  {{
                    $store.getters.games[this.$route.params.gameid].name
                  }}
                </h3>
              </div>
              <!-- <div class="text-counter">Matching …. 5s</div> -->
              <div class="opponent-playerbox">
                <div class="player-col">
                  <span class="player-icon">
                    <img
                      :src="getBattleInfo.user.profileImageUrl"
                      v-if="getBattleInfo.user.profileImageUrl !== ''"
                    />
                    <img
                      src="/static/images/battle_user.png"
                      v-if="getBattleInfo.user.profileImageUrl === ''"
                    />
                  </span>
                  <div class="player-tag">{{ this.battleinfo.user.name }}</div>
                  <div v-if="this.battleinfo.status === 'PLAYING'">
                    {{ this.battleinfo.user.score }}
                  </div>
                </div>

                <div class="player-col">
                  <span class="player-icon opponent-player">
                    <div
                      class="loader-spin"
                      v-if="this.battleinfo.status === 'SEARCHING'"
                    >
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>
                    </div>

                    <div
                      v-if="
                        this.battleinfo.status === 'CONNECTED' ||
                          this.battleinfo.status === 'PLAYING'
                      "
                    >
                      <img
                        :src="this.battleinfo.opponent.profileImageUrl"
                        v-if="this.battleinfo.opponent.profileImageUrl !== ''"
                      />
                      <img
                        src="/static/images/battle_user.png"
                        v-if="this.battleinfo.opponent.profileImageUrl === ''"
                      />
                    </div>
                  </span>

                  <div
                    class="player-tag"
                    v-if="
                      this.battleinfo.status === 'CONNECTED' ||
                        this.battleinfo.status === 'PLAYING'
                    "
                  >
                    {{ this.battleinfo.opponent.name }}
                  </div>

                  <div v-if="this.battleinfo.status === 'PLAYING'">
                    {{ this.battleinfo.opponent.score }}
                  </div>
                </div>
              </div>

              <button
                type="button"
                class="grad_green_btn"
                @click="playGame"
                v-if="this.$data.battleinfo.status === 'PLAYING'"
              >
                खेलें
              </button>
            </div>

            <h4 v-if="this.$data.battleinfo.status === 'PLAYING'">
              {{ $t('Battle') }} {{ getTime(this.battleinfo.startTime) }} से{{
                $t('From')
              }}
              {{ getTime(this.battleinfo.endTime) }} {{ $t('Willruntill') }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import constants from '../constants'
export default {
  name: 'BattleSearch',
  data() {
    return {
      games: [],
      contestuserinfo: {},
      contests: null,
      selected: 0,
      selectedContest: null,
      loading: false,
      userInfo: null,
      joined: true,
      spinnerLoading: false,
      carouselgames: [],
      isOpen: false,
      showModal: false,
      chats: [],
      chatMsg: '',
      contestScore: [],
      battleinfo: {},
      partnerFound: false,
      noplayerfound: false,
      successMessage: ''
    }
  },
  mounted() {
    var _this = this
    this.changeLocale()
    _this.database.ref(`gameContests/${this.$route.params.gameid}`).off()
    var xauthid = this.$cookie.get('X-Auth-Id')
    var url =
      'user_battle_status/' +
      xauthid +
      '/games/' +
      this.$route.params.gameid +
      '/battles/' +
      this.$route.params.battleId

    this.database.ref(url).on('value', function(snapshot) {
      if (snapshot.val() != null) {
        _this.$data.battleinfo = snapshot.val()
        if (
          _this.$data.battleinfo.status == 'CONNECTED' ||
          _this.$data.battleinfo.status == 'PLAYING'
        ) {
          _this.$data.partnerFound = true
          _this.$data.noplayerfound = false
        } else if (_this.$data.battleinfo.status == 'SEARCHING') {
          _this.$data.noplayerfound = false
        } else {
          _this.$data.noplayerfound = true
        }
      } else {
        _this.$http
          .get(constants.BATTLEURL + _this.$route.params.battleId)
          .then(
            function(data) {
              _this.$data.battleinfo = data.body
              _this.$data.noplayerfound = true
              if (_this.$data.battleinfo.status === 'MATCH_NOT_FOUND') {
                _this.$data.successMessage =
                  _this.$data.battleinfo.successMessage
              } else if (this.$data.battleinfo.status === 'COMPLETED') {
                _this.$data.successMessage =
                  _this.$data.battleinfo.successMessage
              }
            },
            response => {}
          )

        // window.history.go(-1)
        // window.location.href=window.location.origin+"/#/battle/"+_this.$route.params.index+"/"+_this.$route.params.battleId
      }
    })
  },

  methods: {
    changeLocale(locale) {
      this.$i18n.locale = this.$cookie.get('lang') || 'hi'
      if (this.$cookie.get('lang') == 'ml') {
        document.body.classList.add('activeMultiLang')
      }
    },
    goBack() {
      var _this = this
      window.history.go(-1)
      window.location.href =
        window.location.origin +
        '/#/battle/' +
        _this.$route.params.gameid +
        '/' +
        _this.$route.params.battleId
    },
    getTime(timestamp) {
      var _time = new Date(timestamp).toLocaleTimeString()
      return _time
    },

    playGame() {
      if (window) {
        window.scrollTo(0, 0)
      }
      this.spinnerLoading = true
      this.$http
        .post(
          constants.CONTESTSURL +
            `${this.getBattleInfo.battle_game_reference_id}/player`
        )
        .then(
          function(data) {
            window.location.href = data.body.game.url
          },
          response => {
            this.$notify({
              group: 'low-mcash',
              text: 'Something went wrong'
            })
            this.spinnerLoading = false
            window.history.back()
          }
        )
    }
  },

  computed: {
    getBattleInfo: function() {
      return this.$data.battleinfo
    },
    getSelectedContestid: function() {
      if (this.$store.getters.games.length > 0) {
        if (
          this.$data.battleinfo.battle_game_reference_id in
          this.$store.getters.games[this.$route.params.index].contests
        )
          return this.$store.getters.games[this.$route.params.index].contests[
            this.$data.battleinfo.battle_game_reference_id
          ]
      } else {
        return null
      }
      if (
        this.$data.battleinfo.battle_game_reference_id in
        this.$store.getters.games[this.$route.params.index].contests
      )
        return this.$store.getters.games[this.$route.params.index].contests[
          this.$data.battleinfo.battle_game_reference_id
        ]
      else {
      }
    }
  }
}
</script>

<style scoped>
.opponent-screenbx .inner-opponentbx h4 {
  display: block;
  font-size: 1.05em;
  color: #fff;
  /* font-weight: bold; */
  padding: 0px;
  margin: 0px 0px 15px 0px;
  text-transform: uppercase;
  margin-top: 20px;
}
</style>
