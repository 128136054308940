<template>
  <div class="user_follow_detail">
    <div class="user_info_head">
      <i class="material-icons closePop" @click="toggleUserInfo()">clear</i>
      <div class="user_img">
        <img
          :src="seatUserData.profileImageUrl"
          v-if="seatUserData.profileImageUrl"
        />
        <img v-else src="../../assets/images/avtar.png" class="bor-Circle" />
      </div>
      <h3>{{ replcePhoneWithAsterik(seatUserData.nickName) }}</h3>
      <h5>
        <!-- <span>F 27</span> -->
      </h5>
    </div>
    <!-- <div class="follower_detail">
      <div class="follower" @click="follower()">
        <span>Follower</span>
        <span class="count">{{ followers }}</span>
      </div>
      <div class="following" @click="following()">
        <span>Following</span>
        <span class="count">{{ followings }}</span>
      </div>
    </div> -->
    <div class="follow_action">
      <span v-if="seatUserData.xauthid !== user.xauthid">
      <button @click="followMe()" class="btn btn-follow" v-if="follow">Follow</button>
      <button class="btn btn-follow" v-else>Following</button>
      </span>
      <button class="btn btn-follow"
              v-if="seatUserData.xauthid == user.xauthid" @click="leftSeat()">Leave Seat</button>
      <button class="btn btn-sendgift" @click="viewNativeProfile()">View Profile</button>


    </div>

    <div class="actionState" v-if="currentChatroom.xauthid == user.xauthid">
      <!-- <div class="stateCol" @click="viewNativeProfile()">
        <span class="stateIcon"><i class="material-icons">person</i></span>
      <span class="textTitle">Profile</span>
      </div> -->

      <!-- <div class="stateCol">
       <span class="stateIcon"><i class="material-icons">
       call_received
       </i></span>
         <span class="textTitle">Leave Seat</span>
     </div>   -->

      <div class="stateCol"  @click="muteUser()"
           v-if="
          seatNo &&
          currentChatroom.userId === user.uid &&
          currentChatroom.isDefault === false &&
          currentChatroom[seatNo].mute === 'No' &&
          currentChatroom[seatNo].xauthid !== currentChatroom.xauthid
        ">
        <span class="stateIcon"><i class="material-icons">mic</i></span>
        <span class="textTitle">Turn Off</span>
      </div>

      <div class="stateCol" @click="unmuteUser()"
           v-if="
          seatNo &&
          currentChatroom.userId == user.uid &&
          currentChatroom.isDefault === false &&
          currentChatroom[seatNo].mute === 'Yes' &&
          currentChatroom[seatNo].xauthid !== currentChatroom.xauthid
        ">
        <span class="stateIcon"><i class="material-icons">mic_off</i></span>
        <span class="textTitle">Turn on</span>
      </div>

      <div class="stateCol"  @click="kickOut()" v-if="
      seatNo &&
      currentChatroom.userId === user.uid &&
      currentChatroom.isDefault === false &&
      currentChatroom[seatNo].xauthid !== currentChatroom.xauthid
    ">
        <span class="stateIcon"><i class="material-icons">close</i></span>
        <span class="textTitle">Kick out</span>
      </div>

    </div>


  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import { replcePhoneWithAsterik} from '../../utils/groupsHelper'

  export default {
    name: 'UserInfo',
    data() {
      return {
        seatUserData: {},
        followers: 0,
        followings: 0,
        follow: true,
        chatRoomId: this.$route.params.id,
        // serverUrl: null
      }
    },
    props:{
      socket:{
        type: Object
      }
    },
    computed: {
      ...mapState(['seatUser', 'user', 'currentChatroom', 'seatNo']),
      ...mapGetters(['serverUrl'])
    },
   created(){

   },
    async mounted() {
      let _this = this

      if (_this.seatUser) {
        const userData = await _this.$http.get(
          _this.serverUrl + 'api/getuserbyauth/' + _this.seatUser
        )
        if (userData.data.status) {
          _this.seatUserData = userData.data.user_data
        }
        let headers = {
          'X-Auth-Id': this.user.xauthid
        }

        let url = `https://be.payboard.in/PayBoard/rest/user/isFollower/${_this.seatUserData.xauthid}`

        let response = await this.$http.get(
          url, {headers}
        )

        if(response.data.isFollower) _this.follow = false
      }
    },

    methods: {
      replcePhoneWithAsterik,

      async leftSeat() {
        console.log(this.user.xauthid);
        console.log(this.chatRoomId);
        this.socket.emit('leftSeat', {
          xauthid: this.user.xauthid,
          chatRoomId:this.chatRoomId
        })
        this.toggleUserInfo()
      },

      viewNativeProfile() {
        try {
          JSBridgePlugin.showNativeProfileView(this.seatUserData.xauthid)
        } catch (error) {
          // console.log(error)
        }

      }

      ,toggleUserInfo() {
        console.log("Clicked on toggleUserInfo");
        var data = {
          status: false,
          xauthid: ''
          // seatNo:''
        }
        this.$store.commit('ACTIVEUSERINFO', data)
      },

      async followMe() {
        let _this = this

        let headers = {
          'X-Auth-Id': _this.user.xauthid,
        }
        var obj = {
          connectionXAuthId: _this.seatUserData.xauthid,
          source: 'GROUP_91'
        }
        _this.$http
          .post('https://be.payboard.in/PayBoard/rest/user/follow', obj, {
            headers
          })
          .then(
            function(data) {
              if (data.data.data.requestStatus === 'SUCCESS') {
                _this.follow = false
              } else {
                this.$toast.bottom(data.body.message)
              }
            },
            response => {
              this.$toast.bottom('server not responding')
            }
          )
      },

      async kickOut() {
        let _this = this
        let seatData = {
          xauthid: '',
          image: '',
          status: 'open',
          userName: '',
          mute: 'No'
        }

        _this.socket.emit('kickout', {
          chatRoomId: _this.chatRoomId,
          [_this.seatNo]: seatData
        })

        var data = {
          status: false,
          xauthid: '',
          seatNo: ''
        }
        _this.$store.commit('ACTIVEUSERINFO', data)

      },

      muteUser() {
        this.socket.emit('muteUser', {
          chatRoomId: this.chatRoomId,
          xauthid: this.seatUserData.xauthid
        })
        this.toggleUserInfo()
      },

      unmuteUser() {
        this.socket.emit('unmuteUser', {
          chatRoomId: this.chatRoomId,
          xauthid: this.seatUserData.xauthid
        })
        this.toggleUserInfo()
      }

    },


    beforeDestroy() {
      // this.toggleUserInfo()
    }
  }
</script>
