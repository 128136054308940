const mode = "prod";
const mode_variable = require(`./config/${mode}.json`);
export default {
  name: "constants",
  mode: mode_variable.mode,
  WALLET_URL: mode_variable.WALLET_URL,
  CONTESTSURL: mode_variable.CONTESTSURL,
  MESSAGEPUSHURL: mode_variable.MESSAGEPUSHURL,
  JOINGAMEURL: mode_variable.JOINGAMEURL,
  BATTLEURL: mode_variable.BATTLEURL,
  BASICURL: mode_variable.BASICURL,
  PAYBOARDTESTURL: mode_variable.PAYBOARDTESTURL,
  SCRATCHCARDURL: mode_variable.SCRATCHCARDURL,
  Mall_BASE_URL: mode_variable.Mall_BASE_URL,
  GETUSERINFO: mode_variable.GETUSERINFO,
  GETUSERWALLETINFO:mode_variable.GETUSERWALLETINFO,
  FIREBASE_DATABASE_URL:mode_variable.FIREBASE_DATABASE_URL
 };
