<template>
  <section class="game_body">
    <div class="overlay-loader" v-if="loader">
      <!-- this.all.length === 0 ||
      this.allTop.length === 0 ||
      (this.weekly.length === 0 && this.weeklyTop.length === 0) -->
      <div class="loader-spin">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
    <div class="main-screen">
      <div class="inner-container">
        <div v-if="userInfo !== null" class="user-headpanel home_head">
          <div class="textlogo">
            <img src="/static/images/logo_g91.svg" />
          </div>

          <div class="mcashCol">
            <img src="/static/images/games_wallet.svg" class="mcoin-icon" />
            {{ userInfo.gamesWalletBalance }}
          </div>

          <span class="user-icon" @click="slideLeftopen">
            <img
              v-if="userInfo.profileImageUrl"
              :src="userInfo.profileImageUrl"
            />
            <img v-else src="/static/images/profile_ic.svg" />
          </span>
        </div>
        <!-- <div class="user-headpanel">
          <span class="user-icon" @click="slideLeftopen">
            <img :src="user.profileImageUrl" v-if="user.profileImageUrl" />
            <img src="/static/images/user-profile.svg" v-else />
          </span>
          <div class="title">{{ $t('Leaderboard') }}</div>
          <router-link to="/chat-group-detail" class="chat-icon" exact>
            <img src="/static/images/chat-icon.png" />
          </router-link>
        </div>-->

        <div class="leaderboard_cntntT">
          <section class="winner_leaderboard">
            <!-- weekly -->
            <div class="ranking_part" v-if="showTypeWeekly">
              <div
                class="ranks animated fadeInLeft"
                v-if="weeklyTop.length > 1"
              >
                <div class="name_score">
                  <img
                    v-if="weeklyTop[1].profileImageUrl"
                    :src="weeklyTop[1].profileImageUrl"
                    alt
                    class="winner_profile"
                  />
                  <img
                    v-else
                    src="../assets/img/user_images.png"
                    alt
                    class="winner_profile"
                  />
                  <h4>{{ replcePhoneWithAsterik(weeklyTop[1].name) }}</h4>
                  <span class="txt-amt"
                    ><img src="/static/images/rupee.svg" width="20px" />{{
                      weeklyTop[1].amount
                    }}</span
                  >
                </div>
                <div class="score_bor">
                  <img src="../assets/img/2-s.svg" alt />
                </div>
              </div>
              <div class="ranks" v-if="weeklyTop.length > 0">
                <img
                  v-if="weeklyTop[0].profileImageUrl"
                  :src="weeklyTop[0].profileImageUrl"
                  alt
                  class="winner_profile"
                />
                <img
                  v-else
                  src="../assets/img/user_images.png"
                  alt
                  class="winner_profile"
                />
                <h4>{{ replcePhoneWithAsterik(weeklyTop[0].name) }}</h4>
                <span class="txt-amt"
                  ><img src="/static/images/rupee.svg" width="20px" />{{
                    weeklyTop[0].amount
                  }}</span
                >
                <div class="score_bor">
                  <img src="../assets/img/1-f.svg" alt />
                </div>
              </div>
              <div
                class="ranks animated fadeInRight"
                v-if="weeklyTop.length > 2"
              >
                <div class="name_score">
                  <img
                    v-if="weeklyTop[2].profileImageUrl"
                    :src="weeklyTop[2].profileImageUrl"
                    alt
                    class="winner_profile"
                  />
                  <img
                    v-else
                    src="../assets/img/user_images.png"
                    alt
                    class="winner_profile"
                  />
                  <h4>{{ replcePhoneWithAsterik(weeklyTop[2].name) }}</h4>
                  <span class="txt-amt"
                    ><img src="/static/images/rupee.svg" width="20px" />{{
                      weeklyTop[2].amount
                    }}</span
                  >
                </div>
                <div class="score_bor">
                  <img src="../assets/img/3-t.svg" alt />
                </div>
              </div>
            </div>
            <!-- daily -->
            <div class="ranking_part" v-else>
              <div class="ranks animated fadeInRight">
                <div class="name_score" v-if="allTop">
                  <img
                    v-if="allTop[1].profileImageUrl"
                    :src="allTop[1].profileImageUrl"
                    alt
                    class="winner_profile"
                  />
                  <img
                    v-else
                    src="../assets/img/user_images.png"
                    alt
                    class="winner_profile"
                  />
                  <h4>{{ replcePhoneWithAsterik(allTop[1].name) }}</h4>
                  <span class="txt-amt"
                    ><img src="/static/images/rupee.svg" width="20px" />{{
                      allTop[1].amount
                    }}</span
                  >
                </div>
                <div class="score_bor">
                  <img src="../assets/img/2-s.svg" alt />
                </div>
              </div>
              <div class="ranks">
                <img
                  v-if="allTop[0].profileImageUrl"
                  :src="allTop[0].profileImageUrl"
                  alt
                  class="winner_profile"
                />
                <img
                  v-else
                  src="../assets/img/user_images.png"
                  alt
                  class="winner_profile"
                />
                <h4>{{ replcePhoneWithAsterik(allTop[0].name) }}</h4>
                <span class="txt-amt"
                  ><img src="/static/images/rupee.svg" width="20px" />{{
                    allTop[0].amount
                  }}</span
                >
                <div class="score_bor">
                  <img src="../assets/img/1-f.svg" alt />
                </div>
              </div>
              <div class="ranks animated fadeInLeft">
                <div class="name_score" v-if="allTop.length > 2">
                  <img
                    v-if="allTop[2].profileImageUrl"
                    :src="allTop[2].profileImageUrl"
                    alt
                    class="winner_profile"
                  />
                  <img
                    v-else
                    src="../assets/img/user_images.png"
                    alt
                    class="winner_profile"
                  />
                  <h4>{{ replcePhoneWithAsterik(allTop[2].name) }}</h4>
                  <span class="txt-amt"
                    ><img src="/static/images/rupee.svg" width="20px" />{{
                      allTop[2].amount
                    }}</span
                  >
                </div>
                <div class="score_bor">
                  <img src="../assets/img/3-t.svg" alt />
                </div>
              </div>
            </div>
          </section>

          <section class="winner_wrap wdth100">
            <div class="winners_list wdth100">
              <div class="filter_winners wdth100">
                <ul class="winners_tabbing">
                  <li :class="showTypeWeekly ? 'active' : ''">
                    <a
                      @click="showTypeWeekly = true"
                      data-toggle="tab"
                      href="#weekly"
                      >{{ $t("Weekly") }}</a
                    >
                  </li>
                  <li :class="!showTypeWeekly ? 'active' : ''">
                    <a
                      data-toggle="tab"
                      @click="showTypeWeekly = false"
                      href="#all_days"
                      >{{ $t("TillNow") }}</a
                    >
                  </li>
                </ul>
                <div class="tab-content wdth100 boardlisting">
                  <div
                    id="weekly"
                    v-if="showTypeWeekly"
                    class="tab-pane fade in "
                  >
                    <div
                      class="rank_col"
                      v-for="(w, index) in weekly"
                      :key="index"
                    >
                      <div class="rank_col_lt">
                        <span class="rank">{{ index + 4 }}</span>
                        <span class="winner_name">
                          <img
                            class="lozad"
                            src="../assets/images/gray_image.jpg"
                            :data-src="
                              w.profileImageUrl == null
                                ? '/static/images/user-profile.svg'
                                : w.profileImageUrl
                            "
                          />
                          {{ replcePhoneWithAsterik(w.name) }}
                        </span>
                      </div>
                      <span class="coins">
                        <img src="/static/images/rupee.svg" width="20px" />
                        {{ w.amount }}
                        <!-- <span>Score</span> -->
                      </span>
                    </div>
                  </div>

                  <div id="all_days" v-else class="tab-pane fade">
                    <div
                      class="rank_col wdth100 purple_bg"
                      v-for="(w, index) in all"
                      :key="index"
                    >
                      <div class="rank_col_lt">
                        <span class="rank">{{ index + 4 }}</span>
                        <span class="winner_name">
                          <img
                            class="lozad"
                            src="../assets/images/gray_image.jpg"
                            :data-src="
                              w.profileImageUrl == null
                                ? '/static/images/user-profile.svg'
                                : w.profileImageUrl
                            "
                          />
                          {{ replcePhoneWithAsterik(w.name) }}
                        </span>
                      </div>
                      <span class="coins">
                        <img src="/static/images/rupee.svg" width="20px" />
                        {{ w.amount }}
                        <!-- <span>Score</span> -->
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import constants from "../constants";
import { mapGetters } from "vuex";
import getUserInfo from "../mixins/getUserInfo";
import slideOpenClose from "../mixins/slideOpenClose";
import { lozadObserver } from "../main";
export default {
  name: "LeaderBoard",
  data() {
    return {
      user: {
        profileImageUrl: "/static/images/user_profile.png",
        balance: 0,
        boardCoin: 0
      },
      all: [],
      weekly: [],
      weeklyTop: [],
      allTop: [],
      showTypeWeekly: true,
      loader: true
    };
  },
  mixins: [getUserInfo, slideOpenClose],
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.$data.loader = true;
    let xauthid = this.$cookie.get("X-Auth-Id");
    this.changeLocale();
    this.getAll();
    this.getWeekly();
    this.getUserInfo(xauthid);
    this.$data.loader = false;
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = this.$cookie.get("lang") || "hi";
      if (this.$cookie.get("lang") == "ml") {
        document.body.classList.add("activeMultiLang");
      }
    },

    getWeekly() {
      this.$http
        .get(constants.BASICURL + "/leaders/weekly/0?wallet=GAMES_WALLET")
        .then(
          function(data) {
            //console.log(data);
            var _ = data.body.leaders;
            this.$data.weeklyTop = _.splice(0, 3);
            this.$data.weekly = _;
            this.$nextTick().then(() => {
              lozadObserver.observe();
            });
          },
          response => {
            //console.log(response);
          }
        );
    },
    getAll() {
      this.$http
        .get(constants.BASICURL + "/leaders/0?wallet=GAMES_WALLET")
        .then(
          function(data) {
            //console.log(data);
            var _ = data.body.leaders;
            this.$data.allTop = _.splice(0, 3);
            // else
            // this.$data.allTop = []
            this.$data.all = _;
            this.$nextTick().then(() => {
              lozadObserver.observe();
            });
          },
          response => {
            //console.log(response);
          }
        );
    },
    isDigit(text) {
      return /^\d+$/.test(text);
    },
    replcePhoneWithAsterik(text) {
      if (this.isDigit(text)) {
        return text.replace(/\d(?=\d{3})/g, "*");
      } else {
        return text;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
