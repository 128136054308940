import CryptoJS from 'crypto-js'
export const isDigit = text => /^\d+$/.test(text)
export const replcePhoneWithAsterik = text => {
  if (isDigit(text)) {
    return text.replace(/\d(?=\d{3})/g, '*')
  } else {
    return text
  }
}

export const parsoDi = obj =>
  CryptoJS.AES.encrypt(JSON.stringify(obj), 'cc02d2128432eb4e1efdfe013622397d').toString()

export const initAgora = (streamList, displayMode) => {

  let $ = this
  // rerendering
  let canvas = document.querySelector('#ag-canvas')
  // pip mode (can only use when less than 4 people in channel)
  if (displayMode === 'pip') {
    let no = streamList.length
    if (no > 4) {
      $.displayMode = 'tile'
      return
    }

    streamList.map((item, index) => {
      let id = item.getId()
      let dom = document.querySelector('#ag-item-' + id)
      if (!dom) {
        dom = document.createElement('section')
        dom.setAttribute('id', 'ag-item-' + id)
        dom.setAttribute('class', 'ag-item')
        canvas.appendChild(dom)
        item.play('ag-item-' + id)
      }
      if (index === no - 1) {
        dom.setAttribute('style', `grid-area: span 12/span 24/13/25`)
      } else {
        dom.setAttribute(
          'style',
          `grid-area: span 3/span 4/${4 + 3 * index}/25;
          z-index:1;width:calc(100% - 20px);height:calc(100% - 20px);display:none`
        )
      }
      item.player.resize && item.player.resize()
    })
  } else if (displayMode === 'tile') {
    // tile mode
    let no = streamList.length
    streamList.map((item, index) => {
      let id = item.getId()
      let dom = document.querySelector('#ag-item-' + id)
      if (!dom) {
        dom = document.createElement('section')
        dom.setAttribute('id', 'ag-item-' + id)
        dom.setAttribute('class', 'ag-item')
        canvas.appendChild(dom)
        item.play('ag-item-' + id)
      }
      dom.setAttribute('style', `grid-area: ${tile_canvas[no][index]}`)
      if (item.player) item.player.resize && item.player.resize()
    })
  } else if (displayMode === 'share') {
    // screen share mode (tbd)
  }
}
