<template>
  <section class="game-details mahasangram-details" v-if="getSelectedGame">
    <div class="detail-header" :style="getBgColor(getSelectedGame.bgColor)">
      <span class="go-back" @click="goBack">
        <img src="/static/images/go-back-icon.png" />
      </span>
      <div class="crcikert_header" v-if="getSelectedGame!=null">
        <h3>{{getSelectedGame.name}}</h3>
        <span class="title">{{$t('Battle')}}</span>
      </div>
    </div>
    <div class="runner-status animated fadeInLeft">
      <div class="runner-left">
        <div class="text-title">
          <b>{{$t('TotalRewards')}}</b>
        </div>
        <h2>
          <span class="runner-point">
            <img src="/static/images/prize-gift-icon.png" width="28px" />
          </span>
          {{getSelectedContestid.prize}}
        </h2>
      </div>
      <div class="runner-mid">
        <span class="battle-icon">
          <img src="/static/images/battle-icon.png" />
        </span>
      </div>
      <div class="runner-right">
        <div class="scorebox">
          <span
            class="user-point"
          >{{getSelectedContestid.currentPlayers}}/{{getSelectedContestid.playersLimit}}</span>
        </div>

        <div class="scorebox">
          <span
            class="user-point"
          >{{getTimeString(getSelectedContestid.startTime)}} - {{getTimeString(getSelectedContestid.endTime)}}</span>
        </div>
      </div>
    </div>
    <div class="button-box-battle">
      <button
        type="button"
        name="button"
        @click="isOpen=!isOpen"
        class="game_video_button"
      >{{!isOpen? $t('Howtoplay') : $t('stopit')}}</button>

      <button type="button" name="button" class="btn btn-danger" @click="share">{{$t('share')}}</button>
    </div>

    <div id="game_video" v-if="isOpen">
      <iframe
        width="100%"
        height="90%"
        frameborder="0"
        :src="getYouTubeVideo()"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen"
        allowfullscreen
      ></iframe>
    </div>

    <div class="prize-statsbox">
      <div class="point-textbox hide">{{getSelectedContestid.prize}}</div>
      <div class="cash_winner">{{$t('CashWinner')}} 100%</div>
    </div>
    <div class="joinendtime">
      <span>{{$t('LastTime')}} {{getTimeString(getSelectedContestid.joinEndTime)}} {{$t("is")}}</span>
    </div>

    <div class="detail-tabbox animated fadeInUp">
      <ul class>
        <li class="nav-item active">
          <a
            class="nav-link active"
            data-toggle="pill"
            href="#pills-prize"
          >{{$t('Amountdistribution')}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="pill" href="#pills-leadboard">{{$t('Leaderboard')}}</a>
        </li>

        <li class="nav-item hide">
          <a class="nav-link" data-toggle="pill" href="#pills-chat">{{$t('chat')}}</a>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade active in" id="pills-prize">
          <div class="tab-container active in">
            <div class="cash-dist-panel-battle"></div>

            <div class="money-dist-panel">
              <div class="point-distbox">
                <h2>{{$t('Amountdistribution')}}</h2>

                <div class="dist-list">
                  <div class="prize_col wdth100" v-for="r in getSelectedContestid.ranges">
                    <h5>{{$t('rank')}} {{r.min}}</h5>
                    <span>
                      <img src="/static/images/coin.png" v-if="r.creditType=='MCASH'" />
                      <img src="/static/images/rupee.svg" width="20px" v-else />
                      {{r.prize}}
                    </span>
                  </div>
                </div>

                <div class="rule-content">
                  <h2>{{$t('Rules')}}</h2>
                  <ul>
                    <li>
                      <div class="point-num">
                        <span>01</span>
                      </div>
                      <div class="point-text">
                        <p>{{$t('p1')}}</p>
                      </div>
                    </li>

                    <li>
                      <div class="point-num">
                        <span>02</span>
                      </div>
                      <div class="point-text last">
                        <p>{{$t('p2')}}</p>
                      </div>
                    </li>

                    <li>
                      <div class="point-num">
                        <span>03</span>
                      </div>
                      <div class="point-text last">
                        <p>{{$t('p3')}}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- leader board-content start -->
        <div class="tab-pane fade" id="pills-leadboard">
          <div class="lead-inner">
            <ul>
              <li v-for="(score,index) in this.$data.contestScore" :key="index">
                <div class="items user-imgbox">
                  <img
                    :src="score.profileImageUrl"
                    v-if="score.profileImageUrl!==null"
                    @click="openProfile(contestScore[index].xAuthId)"
                  />
                  <img
                    src="/static/images/profile_ic.svg"
                    v-else
                    @click="openProfile(contestScore[index].xAuthId)"
                  />
                  <span class="lead-label">{{index+1}}</span>
                </div>
                <div class="items user-name">{{score.name}}</div>
                <div class="items user-score">
                  <span>{{$t('YourSuccess')}}</span>
                  <br />
                  {{score.score}}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- leader board-content end -->

        <div class="tab-pane fade" id="pills-chat">
          <div class="chat-conatiner chat-srceen">
            <div class="chatlog-screen chat_list">
              <ul>
                <li v-for="(m,index) in chats" :key="index">
                  <div class="chat_wrap">
                    <a href="javascript:void(0)" class="user_icn">
                      <!-- {{m}} -->
                      <img
                        :src="m.profileImageUrl"
                        v-if="m.profileImageUrl"
                        @click="openProfile(m.xAuthId)"
                      />

                      <img
                        src="static/images/profile_ic.svg"
                        v-else
                        @click="openProfile(m.xAuthId)"
                      />
                    </a>
                  </div>
                  <div>
                    <div class="chat-cmmentbox">
                      <h2 class="user_name">{{m.name}}</h2>
                      <p>{{m.t}}</p>
                    </div>
                    <div class="chat_msg">
                      <span class="time-log">{{getTime(chats[index].timestamp)}}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="chat-bottom"
              v-bind:class="{'not-start': getSelectedContestid.status==='SCHEDULED'}"
            >
              <input type="text" v-model="chatMsg" class="input-textbg" />
              <button class="btn chat-send" @click="addChat">
                <i class="fa fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="midbot-content">
      <div class="play_buttons">
        <button
          type="button"
          @click="joinGame($route.params.contestId)"
          class="grad_green_btn"
          v-bind:style="{ display: ( (joined || getSelectedContestid.status === 'SCHEDULED') ? 'none': 'block'  ) }">
          {{$t('Play')}}
          <img src="/static/images/coin.png" />
          {{getSelectedContestid.fee}} {{$t('In')}}
        </button>

        <button
          type="button"
          @click="playGame"
          class="grad_green_btn"
          v-bind:style="{ display: ( (!joined) ? 'none': 'block'  ) }"
        >{{$t('Play')}}</button>
      </div>

      <div class="game_status">
        <span v-if="getSelectedContestid.status == 'SCHEDULED'">
          {{$t('Gameisstarting')}}
          <span
            class="timer"
          >{{remainingTimeString(getSelectedContestid.startTime)}}</span>
          {{$t('In')}}
        </span>
        <span v-if="getSelectedContestid.status == 'LIVE'">
          {{$t('Gameisending')}}
          <span
            class="timer"
          >{{remainingTimeString(getSelectedContestid.endTime)}}</span>
          {{$t('In')}}
        </span>
      </div>

      <div class="loading-spinner">
        <circle-spin v-if="spinnerLoading"></circle-spin>
      </div>

      <div v-if="!playersFull">{{$t('p4')}}</div>
      <notifications
        classes="vue-notification-style animated fadeInRight"
        position="top right"
        group="low-mcash"
      />
    </div>
  </section>
</template>

<script>
import constants from "../constants";
import joinGame from "../mixins/joinGame";
export default {
  name: "BATTLE",
  data() {
    return {
      games: [],
      contests: null,
      selected: 0,
      selectedContest: null,
      loading: true,
      userInfo: null,
      joined: false,
      spinnerLoading: false,
      carouselgames: [],
      isOpen: false,
      showModal: false,
      chats: [],
      chatMsg: "",
      contestScore: [],
      battleinfo: {}
    };
  },
  mixins:[joinGame],
  mounted() {
    var _this = this;
    this.changeLocale();
    _this.$http.get(constants.CONTESTSURL + _this.$route.params.contestId).then(
      function(data) {
        this.$data.battleinfo = data.body;
      },
      reponse => {}
    );

    this.getBattleScore();
    var xauthid = this.$cookie.get("X-Auth-Id");
    var url =
      "user_battle_status/" +
      xauthid +
      "/games/" +
      this.$route.params.gameid +
      "/battles/" +
      this.$route.params.contestId;
    this.spinnerLoading = true;
    this.database
      .ref(url)
      .once("value")
      .then(function(snapshot) {
        _this.spinnerLoading = false;

        if (snapshot.val() != null) {
          _this.$router.push(
            `/opponent-screen/${_this.$route.params.gameid}/${_this.$route.params.contestId}`
          );
          this.$data.loading = false;
        }
      });
  },

  methods: {
    changeLocale(locale) {
      this.$i18n.locale = this.$cookie.get("lang") || "hi";
      if (this.$cookie.get("lang") == "ml") {
        document.body.classList.add("activeMultiLang");
      }
    },

    getBgColor(gamecolor) {
      return {
        background: `linear-gradient(100deg,${gamecolor}, ${gamecolor}, transparent)`
      };
    },
    addChat() {
      var obj = {
        name: this.$data.battleinfo.name,
        contestReferenceId: this.$route.params.contestId,
        t: this.chatMsg,
        profileImageUrl: this.$data.battleinfo.profileImageUrl
      };
      this.$http.post(constants.MESSAGEPUSHURL, obj).then(
        function(data) {
          this.chatMsg = "";
        },
        response => {
          this.$notify({
            group: "low-mcash",
            text: "Something went wrong"
          });
        }
      );
    },

    getBattleScore() {
      this.$http
        .get(constants.CONTESTSURL + `${this.$route.params.contestId}/leaders`)
        .then(
          function(data) {
            var _ = data.body.leaders;
            this.$data.contestScore = _;
          },
          response => {}
        );
    },

    getTime(timestamp) {
      var _time = new Date(timestamp).toLocaleTimeString();
      return _time;
    },

    openProfile(xAuthId) {
      this.xAuthId = xAuthId;
      try {
        JSBridgePlugin.showNativeProfileView(xAuthId);
        console.log(xAuthId);
      } catch (err) {
        console.log(err);
      }
    },

    connectToChat() {
      var _this = this;
      this.database
        .ref(`chat/${this.$route.params.contestId}`)
        .on("child_added", function(data) {
          var doc = data.val();
          _this.chats.push(doc);
          setTimeout(function() {
            var a = $(".chatlog-screen");
            a.animate({ scrollTop: a[0].scrollHeight });
          }, 10);
        });
    },
    goBack() {
      this.$router.go(-1);
    },

    share() {
      var name = this.getSelectedGame.name;
      this.$http
        .get(
          constants.PAYBOARDTESTURL +
            `/PayBoard/rest/commons/fetchMiniappShareMessage?gameName=${name}`
        )
        .then(function(data) {
          var obj = {
            message: data.body,
            imageUrl: "",
            isStatusShare: true
          };
          try {
            JSBridgePlugin.share(JSON.stringify(obj));
          } catch (err) {}
        });
    },

    clearSelected() {
      this.$data.selectedContest = null;
      this.$data.userInfo = null;
      this.$data.joined = false;
    },

    getModal: function() {
      showModal: true;
    },

    getYouTubeVideo: function() {
      var url =
        "https://www.youtube.com/embed/" +
        this.$store.getters.games[this.$route.params.gameid].videoId;
      return url;
    },

    playersFull() {
      if (
        this.selectedContest.currentPlayers < this.selectedContest.playersLimit
      ) {
        return true;
      } else {
        return false;
      }
    },

    openGame(game) {
      this.$data.selectedContest = game;

      var url =
        "contest_qualified_users/" +
        game.referenceId +
        "/" +
        this.$cookie.get("X-Auth-Id");

      var _this = this;
      if (game.status == "LIVE") {
        this.database
          .ref(url)
          .once("value")
          .then(function(snapshot) {
            if (snapshot.val() != null) {
              _this.$data.joined = true;
              _this.$http.get(constants.CONTESTSURL + game.referenceId).then(
                function(data) {
                  _this.$data.userInfo = data.body;
                },
                reponse => {}
              );
            } else {
              _this.$data.joined = false;
            }
            _this.$data.loading = false;
          });
      } else {
        _this.$data.loading = true;
      }
    },
    playGame() {
      if (window) {
        window.scrollTo(0, 0);
      }
      this.spinnerLoading = true;
      this.$http
        .post(constants.CONTESTSURL + `${this.$route.params.contestId}/player`)
        .then(
          function(data) {
            console.log(data);
            var _ = data.body.game.url;

            if (
              (this.$cookie.get("source") == "dailyboard" &&
                this.$cookie.get("version-code") >= 48) ||
              (this.$cookie.get("source") == "money91_9app" &&
                this.$cookie.get("version-code") >= 3)
            ) {
              window.open(_);
            } else {
              window.location.href = data.body.game.url;
            }

            /*if(this.$cookie.get("X-Auth-Id") == "8f14e45fceea167a5a36dedd4bea2543" || this.$cookie.get("X-Auth-Id") == "da4fb5c6e93e74d3df8527599fa62642" ||this.$cookie.get("X-Auth-Id") ==  "c81e728d9d4c2f636f067f89cc14862c"){
              // window.location.hash += _.substr(_.indexOf("?"),_.length)
              // window.location.pathname = _.substring(_.indexOf("1/")+1,_.lastIndexOf("/"))
              //JSBridgePlugin.openURL(_)
              window.open(_)
            }else{
              window.location.href = data.body.game.url
            } */

            //a.substring(a.indexOf("1/")+1,a.lastIndexOf("/"))
            // window.open(data.body.game.url)
            //location.href =  "http://tournament.money91.com/DontCrash/?uid=da4fb5c6e93e74d3df8527599fa62642&gameid=35"//data.body.game.url;
          },
          response => {
            //console.log(response);
            this.$notify({
              group: "low-mcash",
              text: data.data.failMessage
            });
            this.spinnerLoading = false;
          }
        );
    },

    getHomeData() {
      this.$http
        .get(constants.CONTESTSURL)
        .then(function(data) {}, response => {});
    },
    openCarouselGame(game) {
      for (var a in this.$data.games) {
        if (game.name == this.$data.games[a].name) {
          this.$data.selected = a;
          break;
        }
      }
      this.openGame(game.contestData);
    },
    getTimeString(time) {
      var _ = new Date(time);
      var min =
        _.getMinutes() < 10 ? "0" + _.getMinutes() : "" + _.getMinutes();
      return _.getHours() + ":" + min;
    },
    remainingTimeString(time) {
      var a = time;
      var b = new Date().getTime();

      var distance = a - b;

      var hrs = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var sec = Math.floor((distance % (1000 * 60)) / 1000);
      return hrs + " Hours " + min + " Minutes " + sec + " Secs";
    }
  },

  computed: {
    getSelectedGame: function() {
      return this.$store.getters.games[this.$route.params.gameid];
    },

    getSelectedContestid: function() {
      if (
        this.$route.params.contestId in
        this.$store.getters.games[this.$route.params.gameid].contests
      ) {
        return this.$store.getters.games[this.$route.params.gameid].contests[
          this.$route.params.contestId
        ];
      } else {
        return {};
      }
    },

    getUserInfo: function() {
      return this.$store.getters.userInfo;
    }
  }
};
</script>

<style>
@import "~@/assets/css/style.css";
.midbot-content {
  position: fixed;
  bottom: 0px;
  background: rgba(255, 255, 255, 10);
  padding: 7px 0 0 0;
}

.game-details {
  padding-bottom: 100px;
}

.user-dashbrd {
  background: #585bce;
  width: 100%;
  height: auto;
  padding: 15px;
  margin: 0 0 40px 0;
  position: relative;
  display: flex;
}

.user-dashbrd .rank-title-btm {
  width: 70%;
  position: absolute;
  left: 50%;
  top: auto;
  bottom: -30px;
  -webkit-transform: translateX(-50%) rotate(-180deg);
  -moz-transform: translateX(-50%) rotate(-180deg);
  -ms-transform: translateX(-50%) rotate(-180deg);
  -o-transform: translateX(-50%) rotate(-180deg);
  transform: translateX(-50%) rotate(-180deg);
  text-align: center;
  border-radius: 40px 40px 0px 0px;
  border-bottom: 32px solid #585bce;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}

.user-dashbrd .rank-title-btm h2 {
  font-size: 16px;
  width: 100%;
  position: absolute;
  text-align: center;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  top: 5px;
  color: #fff;
  font-weight: bold;
}

.user-dashbrd .rank-title-btm h2:before {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 7px solid #fff;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  top: -10px;
  content: "";
  left: 50%;
}

.user-dashbrd .user-point-view {
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.user-dashbrd .user-point-view .point-col {
  display: flex;
  flex-direction: column;
  width: 33.333%;
  align-items: center;
  color: #fff;
  font-size: 14px;
  color: #fff;
  align-self: flex-end;
}

.user-dashbrd .user-point-view .point-col span {
  text-align: center;
  white-space: nowrap;
}

.user-dashbrd .user-point-view .point-col.high-score-active span {
  font-size: 1.4rem;
}

.user-dashbrd .user-point-view .point-col .user-icon {
  width: 42%;
}

.user-dashbrd .user-point-view .point-col .user-icon img {
  max-width: 100%;
  height: auto;
  border-radius: 100px;
  border: 2px solid #fff;
}

.user-dashbrd .user-point-view .point-col.high-score-active {
  position: relative;
}

.user-dashbrd .user-point-view .point-col .user-rank {
  border: 2px solid #6666ff;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    top,
    rgb(255, 255, 255) 0%,
    rgb(229, 229, 229) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgb(255, 255, 255) 0%,
    rgb(229, 229, 229) 100%
  );
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255) 0%,
    rgb(229, 229, 229) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5', GradientType=0);
  color: #ff3333;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px 0 0px 10px;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  line-height: 40px;
}

.user-dashbrd .user-point-view .point-col.high-score-active .user-icon {
  width: 54%;
}

.user-dashbrd .user-point-view .point-col.high-score-active .user-rank {
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  font-size: 28px;
}

.user-dashbrd .user-point-view .point-col:last-child .user-rank {
  border-radius: 0px 10px 10px 0px;
}
</style>
