<template>
  <section class="game_body winner-panel">
    <div class="main-screen">
      <div class="inner-container">
        <div class="winner-screenbx">
          <span class="go-back" @click="goBack()">
            <img src="/static/images/go-back-icon.png" />
          </span>

          <div class="winner-content">
            <div class="win-title">{{ $t('you') }} {{ $t('win') }}</div>
            <div class="player-winbox">
              <span class="battle-icon">
                <img src="/static/images/win-battle-icon.png" />
              </span>
              <div class="player-wincol">
                <div class="player-point">2</div>
                <div class="player-icon">
                  <img src="/static/images/user.png" />
                </div>
                <div class="palyer-name">Salman Raj</div>
              </div>

              <div class="player-wincol win-player">
                <div class="player-point">1</div>
                <div class="player-icon">
                  <img src="/static/images/user.png" />
                  <span class="win-icon">
                    <img src="/static/images/crown-icon.png" />
                  </span>
                </div>
                <div class="palyer-name">Ankit Gupta</div>
              </div>
            </div>
          </div>

          <div class="bottom-btnbox">
            <div class="btn-textbox">
              <span class="play-gamebtn">{{ $t('Playagain') }}</span>
            </div>
            <div class="btn-textbox">
              <span class="more-gamebtn">More Games</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import constants from "../constants";
import getUserInfo from "../mixins/getUserInfo";
export default {
  name: "WinnerScreen",
  data() {
    return {
      games: [],
      contests: null,
      selected: 0,
      loading: true,
      userInfo: null,
      joined: false,
      spinnerLoading: false,
      xauthid: null,
      bgColors: ["red", "green", "blue"],
      spinnerColor: "#FFF",
      user: {
        profileImageUrl: "/static/images/user_profile.png",
        balance: 0,
        boardCoin: 0
      }
    };
  },
  mixins:[getUserInfo],
  async mounted() {
    let _this = this;
    this.changeLocale();

    this.$data.rand = Math.floor(Math.random() * 27) + 7;
    this.$data.xauthid = this.$cookie.get("X-Auth-Id");
   await this.getUserInfo(_this.$data.xauthid);
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = this.$cookie.get("lang") || "hi";
      if (this.$cookie.get("lang") == "ml") {
        document.body.classList.add("activeMultiLang");
      }
      console.log({ lang: this.$cookie.get("lang") });
    },
    goBack() {
      window.history.back();
    },


    openGameLevel(refId, index) {
      let _this = this;
      _this.spinnerLoading = true;
      _this.$data.selected = index;
      this.$store.commit("updateSelected", {
        selected: _this.$data.selected
      });

      _this.database
        .ref(`gameContests/${refId}`)
        .once("value", function(snapshot) {
          console.log(snapshot.val());
          if (snapshot.val() !== null) {
            // _this.$emit('updateContests', snapshot.val())
            _this.$data.selectedGame = snapshot.val();
          } else {
            // this.$router.push('/')
          }
          var url = `/${_this.$data.selectedGame.gameReferenceId}`;
          console.log(url);
          _this.spinnerLoading = false;
          _this.$router.push(url);
        });
    },

    clearSelected() {
      this.$data.selectedContest = null;
      this.$data.userInfo = null;
      this.$data.joined = false;
      this.$data.showModal = false;
    },

    getHomeData() {
      this.$http.get(constants.CONTESTSURL).then(
        function(data) {
          //console.log(data);
          // this.$data.games = data.body.gameContests
          // this.$data.contests = this.$data.games[0].contests
        },
        response => {
          //console.log("error", response);
        }
      );
    }
  },

  computed: {
    getGames: function() {
      return this.$store.getters.games;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
