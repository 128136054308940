<template>
  <div class="lead-col">
    <div class="card-list">
      <div class="leader-icon">
        <span v-if="rank === 1" class="win">
<!--          <CrownIcon />-->
        </span>
        <span class="rank">{{ winnerInfo ? rank : "" }}</span>
        <img
          :src="
            winnerInfo
              ? winnerInfo['profileImageUrl'] ||
                require('@/assets/images/profile.png')
              : ''
          "
          alt=""
        />
      </div>
      <div class="leader-title">
        {{ winnerInfo ? winnerInfo.name : "" }}
      </div>
      <div class="eraining">
        {{ winnerInfo ? `Total Earning: ${winnerInfo.amount}` : "" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RankCard",
  components: {
    // CrownIcon: () => import("@/svgicons/CrownIcon")
  },
  props: {
    winner: {
      type: Object,
      required: true,
      default: null
    },
    rank: {
      type: Number,
      required: true
    }
  },
  computed: {
    winnerInfo() {
      if (Object.keys(this.winner).length) return this.winner;
      return null;
    }
  }
};
</script>

<style scoped></style>
