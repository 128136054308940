var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"game_body opponent-panel"},[_c('div',{staticClass:"main-screen"},[(
        this.battleinfo.status === 'MATCH_NOT_FOUND' ||
          this.battleinfo.status === 'COMPLETED'
      )?_c('div',{staticClass:"results-box"},[_c('p',[_vm._v(_vm._s(this.$data.battleinfo.successMessage))]),_c('span',{staticClass:"go-back-battle",on:{"click":_vm.goBack}},[_vm._v(_vm._s(_vm.$t('Tryagain'))+" ")])]):_vm._e(),_c('div',{staticClass:"inner-container"},[(
          this.battleinfo.status === 'SEARCHING' ||
            this.battleinfo.status === 'CONNECTED' ||
            this.battleinfo.status === 'PLAYING'
        )?_c('div',{staticClass:"opponent-screenbx"},[_c('div',{staticClass:"inner-opponentbx"},[_c('h2',[_vm._v(_vm._s(this.$data.battleinfo.status))]),_c('div',{staticClass:"opponent-content"},[_c('div',{staticClass:"title-block"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.games[this.$route.params.gameid].name)+" ")])]),_c('div',{staticClass:"opponent-playerbox"},[_c('div',{staticClass:"player-col"},[_c('span',{staticClass:"player-icon"},[(_vm.getBattleInfo.user.profileImageUrl !== '')?_c('img',{attrs:{"src":_vm.getBattleInfo.user.profileImageUrl}}):_vm._e(),(_vm.getBattleInfo.user.profileImageUrl === '')?_c('img',{attrs:{"src":"/static/images/battle_user.png"}}):_vm._e()]),_c('div',{staticClass:"player-tag"},[_vm._v(_vm._s(this.battleinfo.user.name))]),(this.battleinfo.status === 'PLAYING')?_c('div',[_vm._v(" "+_vm._s(this.battleinfo.user.score)+" ")]):_vm._e()]),_c('div',{staticClass:"player-col"},[_c('span',{staticClass:"player-icon opponent-player"},[(this.battleinfo.status === 'SEARCHING')?_c('div',{staticClass:"loader-spin"},[_c('div',{staticClass:"bounce1"}),_c('div',{staticClass:"bounce2"}),_c('div',{staticClass:"bounce3"})]):_vm._e(),(
                      this.battleinfo.status === 'CONNECTED' ||
                        this.battleinfo.status === 'PLAYING'
                    )?_c('div',[(this.battleinfo.opponent.profileImageUrl !== '')?_c('img',{attrs:{"src":this.battleinfo.opponent.profileImageUrl}}):_vm._e(),(this.battleinfo.opponent.profileImageUrl === '')?_c('img',{attrs:{"src":"/static/images/battle_user.png"}}):_vm._e()]):_vm._e()]),(
                    this.battleinfo.status === 'CONNECTED' ||
                      this.battleinfo.status === 'PLAYING'
                  )?_c('div',{staticClass:"player-tag"},[_vm._v(" "+_vm._s(this.battleinfo.opponent.name)+" ")]):_vm._e(),(this.battleinfo.status === 'PLAYING')?_c('div',[_vm._v(" "+_vm._s(this.battleinfo.opponent.score)+" ")]):_vm._e()])]),(this.$data.battleinfo.status === 'PLAYING')?_c('button',{staticClass:"grad_green_btn",attrs:{"type":"button"},on:{"click":_vm.playGame}},[_vm._v(" खेलें ")]):_vm._e()]),(this.$data.battleinfo.status === 'PLAYING')?_c('h4',[_vm._v(" "+_vm._s(_vm.$t('Battle'))+" "+_vm._s(_vm.getTime(this.battleinfo.startTime))+" से"+_vm._s(_vm.$t('From'))+" "+_vm._s(_vm.getTime(this.battleinfo.endTime))+" "+_vm._s(_vm.$t('Willruntill'))+" ")]):_vm._e()])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }