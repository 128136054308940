<template>
  <div
    class="newChatRoom"
    style="background-color: #7F00C9"
    v-if="chatRoom"
    id="ag-canvas"
  >
    <div class="chatTitleHeader">
      <div class="go_back" @click="back()">
        <i class="material-icons">close</i>
      </div>
      <div class="chat_title_name">
        <span class="chat_title">{{ chatRoom.roomName }}</span>

        <br />
        <!-- <span class="theme_text">{{ chatRoom.themeCategory }}</span> -->

        <div class="message-note">
          {{ chatRoom.roomMessage }}
        </div>
      </div>

      <span class="share_room">
        <i class="material-icons" @click="shareGroup()">share</i>
      </span>
      <div class="chat_option">
        <span> <i class="fa fa-user"></i> {{ chatRoom.members }} </span>
        <!-- <span @click="toogleOption = !toogleOption">
          <i class="fa fa-bars"></i>
        </span> -->
        <!-- <div v-if="toogleOption" class="toggle_chat_option">
          <ul>
            <li>
              <i class="material-icons">color_lens</i> Theme
            </li>
            <li>
              <i class="material-icons">music_note</i> Music
            </li>
            <li>
              <i class="material-icons">lock</i> Lock
            </li>
            <li>
              <i class="material-icons">exit_to_app</i> Exit
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <main class="main_content">
      <div class="createChatList" v-if="chatRoom">
        <ul class="list_item">
          <li
            v-if="chatRoom.seat1 && chatRoom.seat1.status == 'busy'"
            @click="toggleUserInfo(chatRoom.seat1.xauthid, 'seat1')"
          >
            <div
              class="add_member"
              :class="{
                speaking:
                  chatRoom.seat1.xauthid in speakingHash &&
                  speakingHash[chatRoom.seat1.xauthid]
              }"
            >
              <i
                v-if="chatRoom.seat1.mute == 'Yes'"
                class="material-icons mic-icon"
                >mic_off</i
              >
              <i v-else class="material-icons mic-icon">mic</i>
              <img v-if="chatRoom.seat1.image" :src="chatRoom.seat1.image" />
              <img v-else src="../../assets/images/avtar.png" />
              <img
                class="emojiOnSeat"
                v-if="emojiOnSeat && chatRoom.seat1.xauthid == emojiXauthId"
                :src="emojiOnSeatSrc"
              />
            </div>
            <div class="member_name">
              <span class="home-icon">1</span> {{ chatRoom.seat1.userName }}
            </div>
          </li>
          <li
            v-if="chatRoom.seat1 && chatRoom.seat1.status == 'open'"
            @click="letMeSit('seat1')"
          >
            <div class="add_member">
              <i class="material-icons add-icon zoomPlusscale">mic_none</i>
              <i class="plus-icon"></i>
            </div>
            <div class="member_name">
              <span class="home-icon">1</span>
            </div>
          </li>
          <li
            v-if="chatRoom.seat2 && chatRoom.seat2.status == 'busy'"
            @click="toggleUserInfo(chatRoom.seat2.xauthid, 'seat2')"
          >
            <div
              class="add_member"
              :class="{
                speaking:
                  chatRoom.seat2.xauthid in speakingHash &&
                  speakingHash[chatRoom.seat2.xauthid]
              }"
            >
              <i
                v-if="chatRoom.seat2.mute == 'Yes'"
                class="material-icons mic-icon"
                >mic_off</i
              >
              <i v-else class="material-icons mic-icon">mic</i>
              <img v-if="chatRoom.seat2.image" :src="chatRoom.seat2.image" />
              <img v-else src="../../assets/images/avtar.png" />
              <img
                class="emojiOnSeat"
                v-if="emojiOnSeat && chatRoom.seat2.xauthid == emojiXauthId"
                :src="emojiOnSeatSrc"
              />
            </div>
            <div class="member_name">
              <span class="home-icon">2</span> {{ chatRoom.seat2.userName }}
            </div>
          </li>

          <li
            v-if="chatRoom.seat2 && chatRoom.seat2.status == 'open'"
            @click="letMeSit('seat2')"
          >
            <div class="add_member">
              <i class="material-icons add-icon zoomPlusscale">mic_none</i>
              <i class="plus-icon"></i>
            </div>
            <div class="member_name">
              <span class="home-icon">2</span>
            </div>
          </li>

          <li
            v-if="chatRoom.seat3 && chatRoom.seat3.status == 'busy'"
            @click="toggleUserInfo(chatRoom.seat3.xauthid, 'seat3')"
          >
            <div
              class="add_member"
              :class="{
                speaking:
                  chatRoom.seat3.xauthid in speakingHash &&
                  speakingHash[chatRoom.seat3.xauthid]
              }"
            >
              <i
                v-if="chatRoom.seat3.mute == 'Yes'"
                class="material-icons mic-icon"
                >mic_off</i
              >
              <i v-else class="material-icons mic-icon">mic</i>
              <img v-if="chatRoom.seat3.image" :src="chatRoom.seat3.image" />
              <img v-else src="../../assets/images/avtar.png" />
              <img
                class="emojiOnSeat"
                v-if="emojiOnSeat && chatRoom.seat3.xauthid == emojiXauthId"
                :src="emojiOnSeatSrc"
              />
            </div>
            <div class="member_name">
              <span class="home-icon">3</span> {{ chatRoom.seat3.userName }}
            </div>
          </li>

          <li
            v-if="chatRoom.seat3 && chatRoom.seat3.status == 'open'"
            @click="letMeSit('seat3')"
          >
            <div class="add_member">
              <i class="material-icons add-icon zoomPlusscale">mic_none</i>
              <i class="plus-icon"></i>
            </div>
            <div class="member_name">
              <span class="home-icon">3</span>
            </div>
          </li>

          <li
            v-if="chatRoom.seat4 && chatRoom.seat4.status == 'busy'"
            @click="toggleUserInfo(chatRoom.seat4.xauthid, 'seat4')"
          >
            <div
              class="add_member"
              :class="{
                speaking:
                  chatRoom.seat4.xauthid in speakingHash &&
                  speakingHash[chatRoom.seat4.xauthid]
              }"
            >
              <i
                v-if="chatRoom.seat4.mute == 'Yes'"
                class="material-icons mic-icon"
                >mic_off</i
              >
              <i v-else class="material-icons mic-icon">mic</i>
              <img v-if="chatRoom.seat4.image" :src="chatRoom.seat4.image" />
              <img v-else src="../../assets/images/avtar.png" />
              <img
                class="emojiOnSeat"
                v-if="emojiOnSeat && chatRoom.seat4.xauthid == emojiXauthId"
                :src="emojiOnSeatSrc"
              />
            </div>
            <div class="member_name">
              <span class="home-icon">4</span> {{ chatRoom.seat4.userName }}
            </div>
          </li>

          <li
            v-if="chatRoom.seat4 && chatRoom.seat4.status == 'open'"
            @click="letMeSit('seat4')"
          >
            <div class="add_member">
              <i class="material-icons add-icon zoomPlusscale">mic_none</i>
              <i class="plus-icon"></i>
            </div>
            <div class="member_name">
              <span class="home-icon">4</span>
            </div>
          </li>

          <li
            v-if="chatRoom.seat5 && chatRoom.seat5.status == 'busy'"
            @click="toggleUserInfo(chatRoom.seat5.xauthid, 'seat5')"
          >
            <div
              class="add_member"
              :class="{
                speaking:
                  chatRoom.seat5.xauthid in speakingHash &&
                  speakingHash[chatRoom.seat5.xauthid]
              }"
            >
              <i
                v-if="chatRoom.seat5.mute == 'Yes'"
                class="material-icons mic-icon"
                >mic_off</i
              >
              <i v-else class="material-icons mic-icon">mic</i>
              <img v-if="chatRoom.seat5.image" :src="chatRoom.seat5.image" />
              <img v-else src="../../assets/images/avtar.png" />
              <img
                class="emojiOnSeat"
                v-if="emojiOnSeat && chatRoom.seat5.xauthid == emojiXauthId"
                :src="emojiOnSeatSrc"
              />
            </div>
            <div class="member_name">
              <span class="home-icon">5</span> {{ chatRoom.seat5.userName }}
            </div>
          </li>

          <li
            v-if="chatRoom.seat5 && chatRoom.seat5.status == 'open'"
            @click="letMeSit('seat5')"
          >
            <div class="add_member">
              <i class="material-icons add-icon zoomPlusscale">mic_none</i>
              <i class="plus-icon"></i>
            </div>
            <div class="member_name">
              <span class="home-icon">5</span>
            </div>
          </li>

          <li
            v-if="chatRoom.seat6 && chatRoom.seat6.status == 'busy'"
            @click="toggleUserInfo(chatRoom.seat6.xauthid, 'seat6')"
          >
            <div
              class="add_member"
              :class="{
                speaking:
                  chatRoom.seat6.xauthid in speakingHash &&
                  speakingHash[chatRoom.seat6.xauthid]
              }"
            >
              <i
                v-if="chatRoom.seat6.mute == 'Yes'"
                class="material-icons mic-icon"
                >mic_off</i
              >
              <i v-else class="material-icons mic-icon">mic</i>
              <img v-if="chatRoom.seat6.image" :src="chatRoom.seat6.image" />
              <img v-else src="../../assets/images/avtar.png" />
              <img
                class="emojiOnSeat"
                v-if="emojiOnSeat && chatRoom.seat6.xauthid == emojiXauthId"
                :src="emojiOnSeatSrc"
              />
            </div>
            <div class="member_name">
              <span class="home-icon">6</span> {{ chatRoom.seat6.userName }}
            </div>
          </li>

          <li
            v-if="chatRoom.seat6 && chatRoom.seat6.status == 'open'"
            @click="letMeSit('seat6')"
          >
            <div class="add_member">
              <i class="material-icons add-icon zoomPlusscale">mic_none</i>
              <i class="plus-icon"></i>
            </div>
            <div class="member_name">
              <span class="home-icon">6</span>
            </div>
          </li>

          <li
            v-if="chatRoom.seat7 && chatRoom.seat7.status == 'busy'"
            @click="toggleUserInfo(chatRoom.seat7.xauthid, 'seat7')"
          >
            <div
              class="add_member"
              :class="{
                speaking:
                  chatRoom.seat7.xauthid in speakingHash &&
                  speakingHash[chatRoom.seat7.xauthid]
              }"
            >
              <i
                v-if="chatRoom.seat7.mute == 'Yes'"
                class="material-icons mic-icon"
                >mic_off</i
              >
              <i v-else class="material-icons mic-icon">mic</i>
              <img v-if="chatRoom.seat7.image" :src="chatRoom.seat7.image" />
              <img v-else src="../../assets/images/avtar.png" />
              <img
                class="emojiOnSeat"
                v-if="emojiOnSeat && chatRoom.seat7.xauthid == emojiXauthId"
                :src="emojiOnSeatSrc"
              />
            </div>
            <div class="member_name">
              <span class="home-icon">7</span> {{ chatRoom.seat7.userName }}
            </div>
          </li>

          <li
            v-if="chatRoom.seat7 && chatRoom.seat7.status == 'open'"
            @click="letMeSit('seat7')"
          >
            <div class="add_member">
              <i class="material-icons add-icon zoomPlusscale">mic_none</i>
              <i class="plus-icon"></i>
            </div>
            <div class="member_name">
              <span class="home-icon">7</span>
            </div>
          </li>

          <li
            v-if="chatRoom.seat8 && chatRoom.seat8.status == 'busy'"
            @click="toggleUserInfo(chatRoom.seat8.xauthid, 'seat8')"
          >
            <div
              class="add_member"
              :class="{
                speaking:
                  chatRoom.seat8.xauthid in speakingHash &&
                  speakingHash[chatRoom.seat8.xauthid]
              }"
            >
              <i
                v-if="chatRoom.seat8.mute == 'Yes'"
                class="material-icons mic-icon"
                >mic_off</i
              >
              <i v-else class="material-icons mic-icon">mic</i>
              <img v-if="chatRoom.seat8.image" :src="chatRoom.seat8.image" />
              <img v-else src="../../assets/images/avtar.png" />
              <img
                class="emojiOnSeat"
                v-if="emojiOnSeat && chatRoom.seat8.xauthid == emojiXauthId"
                :src="emojiOnSeatSrc"
              />
            </div>
            <div class="member_name">
              <span class="home-icon">8</span> {{ chatRoom.seat8.userName }}
            </div>
          </li>

          <li
            v-if="chatRoom.seat8 && chatRoom.seat8.status == 'open'"
            @click="letMeSit('seat8')"
          >
            <div class="add_member">
              <i class="material-icons add-icon zoomPlusscale">mic_none</i>
              <i class="plus-icon"></i>
            </div>
            <div class="member_name">
              <span class="home-icon">8</span>
            </div>
          </li>
        </ul>
        <!-- <invite-friend-list
            class="toggle_friend_list"
            :class="{'active-prdt' : activeFriendsList}"
          >
          </invite-friend-list>-->
        <div
          class="followOutercontent"
          :class="{ 'active-prdt': activeUserInfo }"
        >
          <user-info
            v-show="user !== null"
            class="toggle_user_info"
            :socket="socket"
            :class="{ 'active-prdt': getActiveUserInfo }"
            :key="toggleUserInfoKey"
          ></user-info>
        </div>
      </div>

      <div class="chatlog_box">
        <ul v-if="messages" ref="chat_container">
          <li
            v-for="(message, index) in messages"
            :key="index"
            :class="{
              _left: message.type == 'left' || message.type == 'entered'
            }"
          >
            <div class="outerChatting">
              <div class="chatListwrapOut">
                <div class="chatListwrap">
                  <div class="chatListing">
                    <img
                      v-if="message.senderImage"
                      :src="message.senderImage"
                      class="userProfile"
                    />
                    <img
                      v-else
                      src="../../assets/images/avtar.png"
                      class="userProfile"
                    />

                    <div class="userInfotext">
                      <div class="">
                        <span class="message_sender">{{
                          message.senderName
                        }}</span>
                        {{ message.message }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="timeStampbox">
                  <span class="timeText">
                    {{ timeSince(new Date(message.createdAt)) }}
                    ago
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </main>
    <div
      class="emoji_overlay"
      @click="showImoji()"
      :class="{ emojio_verlayactive: showEmoji }"
    ></div>
    <div class="emoji-panel" :class="{ emojiPopactive: showEmoji }">
      <VEmojiPicker
        :showSearch="false"
        :custom="customEmojis"
        :showPreview="false"
        :style="{ width: '100%' }"
        @select="addEmoji"
      />
    </div>
    <footer>
      <input
        type="text"
        name="message"
        autocomplete="off"
        placeholder="Send message..."
        v-model="message"
        @focus="hideImoji()"
      />

      <span class="send_sticker" @click="showImoji()">
        <i class="material-icons">insert_emoticon</i>
      </span>
      <span class="mute_speaker" v-if="iamOnSeat">
        <i class="material-icons" @click="unmute()" v-if="IAmMute">mic_off</i>
        <i class="material-icons" @click="mute()" v-else>mic</i>
      </span>
      <span @click="sendMessage" class="send"
        ><i class="material-icons">send</i></span
      >
      <!-- <span v-if="xauthid =='e4da3b7fbbce2345d7772b0674a318d5' || xauthid =='da4fb5c6e93e74d3df8527599fa62642'" class="share_room">
       <i class="material-icons" @click="show()">loop</i>
     </span>
     <span v-if="xauthid =='e4da3b7fbbce2345d7772b0674a318d5' || xauthid =='da4fb5c6e93e74d3df8527599fa62642'" class="share_room">
       <i class="material-icons" @click="play()">play_arrow</i>
     </span> -->

<!--      <span class="send_reward toggle_reward" @click="toggleRewards()">-->
<!--        <img src="../../assets/images/gift_icon.png" />-->
<!--      </span>-->
    </footer>
<!--    <rewards-list-->
<!--      class="toggle_rewards_list"-->
<!--      :class="{ 'active-prdt': activeReward }"-->
<!--      :key="componentKeyReward"-->
<!--    ></rewards-list>-->

    <loading
      :active.sync="isLoading"
      :color="color"
      :is-full-page="true"
    ></loading>

    <span
      @click="switchDisplay"
      class="ag-btn displayModeBtn"
      title="Switch Display Mode"
    >
      <i class="ag-icon ag-icon-switch-display"></i>
    </span>
    <span
      class="ag-btn disableRemoteBtn"
      :class="{ disabled: streamList.length > 4 || displayMode !== 'pip' }"
      @click="hideRemote"
      title="Hide Remote Stream"
    >
      <i class="ag-icon ag-icon-remove-pip"></i>
    </span>
  </div>
</template>

<script>
import timeAgo from "../../mixins/timeAgo";
import { chat91Firebase } from "../../main";
import { mapState, mapGetters } from "vuex";
import merge from "lodash.merge";
import { AGORA_APP_ID } from "../../agora.config";
import io from "socket.io-client";
import InviteFriendList from "./InviteFriendList";
import RewardsList from "./RewardsList";
import UserInfo from "./UserInfo";
const tile_canvas = {
  "1": ["span 12/span 24"],
  "2": ["span 12/span 12/13/25", "span 12/span 12/13/13"],
  "3": ["span 6/span 12", "span 6/span 12", "span 6/span 12/7/19"],
  "4": [
    "span 6/span 12",
    "span 6/span 12",
    "span 6/span 12",
    "span 6/span 12/7/13"
  ],
  "5": [
    "span 3/span 4/13/9",
    "span 3/span 4/13/13",
    "span 3/span 4/13/17",
    "span 3/span 4/13/21",
    "span 9/span 16/10/21"
  ],
  "6": [
    "span 3/span 4/13/7",
    "span 3/span 4/13/11",
    "span 3/span 4/13/15",
    "span 3/span 4/13/19",
    "span 3/span 4/13/23",
    "span 9/span 16/10/21"
  ],
  "7": [
    "span 3/span 4/13/5",
    "span 3/span 4/13/9",
    "span 3/span 4/13/13",
    "span 3/span 4/13/17",
    "span 3/span 4/13/21",
    "span 3/span 4/13/25",
    "span 9/span 16/10/21"
  ]
};

export default {
  name: "NewChatRoom",
  components: {
    InviteFriendList,
    RewardsList,
    UserInfo,
    VEmojiPicker: () => import("v-emoji-picker")
  },
  data() {
    return {
      socket: null,
      toogleOption: false,
      chatRoom: {},
      chatRoomId: this.$route.params.id,
      message: "",
      messages: [],
      lastTimeout: null,
      showModal: false,
      mySeat: "",
      IAmMute: false,
      iamOnSeat: false,
      connection: {},
      isLoading: true,
      color: "#fff",
      speaking: "",
      xauthid: this.$cookie.get("X-Auth-Id"),
      xauthToken: this.$cookie.get("X-Auth-Token"),
      // user: {},
      componentKeyReward: 0,
      canSpeak: true,
      speakingHash: {},
      timeout: null,

      displayMode: "pip",
      streamList: [],
      attendeeMode: "audience",
      videoProfile: "480p_4",
      channel: this.$route.params.id,
      transcode: "interop",
      appId: AGORA_APP_ID,
      client: {},
      localStream: {},
      remoteStream: {},
      baseMode: "al",
      startTime: "",
      showEmoji: false,
      customEmojis: [
        {
          name: "octocat1",
          short_names: ["octocat1"],
          text: "",
          emoticons: [],
          keywords: ["octocat1"],
          imageUrl:
            "https://media.tenor.com/images/c5116e7e97e9263438ffb3b0f46fd19e/tenor.gif"
        },
        {
          name: "octocat2",
          short_names: ["octocat2"],
          text: "",
          emoticons: [],
          keywords: ["octocat2"],
          imageUrl:
            "https://media.tenor.com/images/5b903cc8d9f789266bdfc73ce1739828/tenor.gif"
        },
        {
          name: "octocat3",
          short_names: ["octocat3"],
          text: "",
          emoticons: [],
          keywords: ["octocat3"],
          imageUrl:
            "https://media.tenor.com/images/b4ba7803ecffb0053926f3730e0cf0fc/tenor.gif"
        }
      ],
      emojiOnSeat: false,
      emojiOnSeatSrc: "",
      emojiXauthId: "",
      showing: true,
      playing: true,
      chatStart: false,
      toggleUserInfoKey: 0,
      // serverUrl:'https://groups.money91.com/',
      chat91FirebaseDatabase: this.$firebase.database(chat91Firebase)
    };
  },
  mixins: [timeAgo],
  async created() {
    let _this = this;
    console.log("Created LifeCycle");
    this.appId = AGORA_APP_ID;
    let connection =
      window.location.hostname === "localhost"
        ? "http://localhost:5001/"
        : "https://groups.money91.com";
    this.socket = io(connection);
    this.socket.on("connect", async function() {
      await _this.setUpGroupChat();
      _this.checkPermissions();
      _this.isLoading = false;
    });
    this.socket.on("disconnect", () => {
      _this.isLoading = false;
    });

    if (!this.appId) return alert("Get App ID first!");
  },

  computed: {
    ...mapState(["activeFriendsList", "activeUserInfo", "activeReward"]),
    ...mapGetters(["serverUrl", "getActiveUserInfo"]),
    user: {
      get() {
        return this.$store.state.user;
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      }
    }
  },
  methods: {
    checkPermissions() {
      let _this = this;
      try {
        __native_money91.checkForPermissionResult = function(res) {
          if (res === "") {
            if (_this.user !== null) _this.initiateChatRoom();
          } else {
            _this.back();
          }
        };
        var _ = [
          // 'android.permission.CAMERA',
          "android.permission.RECORD_AUDIO",
          "android.permission.WRITE_EXTERNAL_STORAGE",
          "android.permission.READ_EXTERNAL_STORAGE"
        ];
        JSBridgePlugin.checkForPermission(
          _,
          true,
          "__native_money91.checkForPermissionResult"
        );
      } catch (e) {
        if (_this.user !== null) _this.initiateChatRoom();
      }
    },
    createToken() {
      let _this = this;
      return this.$http.get(_this.serverUrl + "api/create-token", {
        headers: { xauthToken: _this.xauthToken }
      });
    },
    async setUpGroupChat() {
      let _this = this;
      const chat91FirebaseAuth = this.$firebase.auth(chat91Firebase);
      _this.xauthToken = this.$cookie.get("X-Auth-Token");
      if (_this.xauthToken === null) {
        _this.xauthToken = _this.$route.query.xauthToken;
        if (_this.xauthToken !== null && _this.xauthToken !== undefined)
          this.$cookie.set("X-Auth-Token", _this.xauthToken);
      }
      _this.idToken = this.$cookie.get("idToken");
      if (_this.xauthToken === null || _this.xauthToken === "") return false;

      const getFirebaseToken = await this.createToken();

      if (getFirebaseToken.status === 200 && getFirebaseToken.data.status) {
        let user = getFirebaseToken.data.user;
        user.lastUpdated = new Date().getTime();
        _this.user = user;
        this.$cookie.set("X-Auth-Id", user.xauthid);

        if (_this.idToken === null) {
          let firebaseSignin = await chat91FirebaseAuth.signInWithCustomToken(
            getFirebaseToken.data.customToken
          );
          let token = await chat91FirebaseAuth.currentUser.getIdToken(true);
          _this.idToken = token;
          this.$cookie.set("idToken", _this.idToken);
        }
      } else {
        console.log("You are not authorised");
      }
    },
    show() {
      try {
        JSBridgePlugin.showHideNativeAudioPlayer(this.showing);
      } catch (error) {}
      this.showing = false;
    },
    play() {
      try {
        JSBridgePlugin.playPauseNativeAudioPlayer(this.playing);
      } catch (error) {}
      this.playing = false;
    },
    hideImoji() {
      this.showEmoji = false;
    },
    showImoji() {
      this.showEmoji ? (this.showEmoji = false) : (this.showEmoji = true);
    },
    addEmoji(emoji) {
      if (emoji.custom) {
        if (this.iamOnSeat) {
          this.socket.emit("emoji", {
            roomId: this.chatRoomId,
            emojiUrl: emoji.imageUrl,
            xauthid: this.xauthid
          });
        }
      } else {
        this.message = this.message + " " + emoji.data;
      }
    },
    async shareGroup() {
      let shareUrl = await this.$http.post("https://api.branch.io/v1/url", {
        branch_key: "key_live_cfVgkkPBIPJSmXJaiBjdvmcgtxmtPPbj",
        channel: "groups91",
        feature: "onboarding",
        campaign: "new product",
        stage: "new user",
        tags: ["one", "two", "three"],
        data: {
          id: 162,
          iconImageURL:
            "https://d3ojr524tgla9b.cloudfront.net/ec9af4eb-dbd3-41c3-84cf-626a8442f931_original.png",
          name: "groups91",
          applicationURL:
            "https://groups.money91.com/#/?src=share&type=public&chatRoomId=" +
            this.chatRoomId,
          miniapp: true
        }
      });

      if (shareUrl.status === 200 && shareUrl.data !== undefined) {
        let shareMessage =
          "मस्ती वाली चैट करें ग्रुप्स में ऑडियो के साथ - नए दोस्त बनाएं - MCash कमाएं! ज्वाइन करे मेरा ग्रुप " +
          shareUrl.data.url;
        let shareImage =
          "https://d3ojr524tgla9b.cloudfront.net/ec9af4eb-dbd3-41c3-84cf-626a8442f931_original.png";

        let obj = {
          message: shareMessage,
          imageUrl: shareImage
        };

        try {
          JSBridgePlugin.share(JSON.stringify(obj));
        } catch (err) {
          window.open("whatsapp://send?text=" + shareMessage);
        }
      } else {
        alert("कुछ गलत हो गया। कृपया पुन: प्रयास करें!");
      }
    },
    toggleUserInfo(xauthid, seatNo) {
      this.seatUser = {
        status: true,
        xauthid: xauthid,
        seatNo: seatNo
      };
      this.$store.commit("ACTIVEUSERINFO", this.seatUser);
      this.toggleUserInfoKey += 1;
    },
    toggleRewards() {
      this.$store.commit("ACTIVEREWARD", true);
    },
    toggleInviteFriend() {
      //  this.$modal.show("invite-friend-list");
    },
    async back() {
      //alert('back--'+this.src)
      // if(this.src ==='share') {
      //   this.$router.push('/')
      // } else {
      this.$router.go("-1");
      // }
    },
    async mute() {
      let _this = this;
      _this.localStream.muteAudio();

      _this.IAmMute = true;
      var seat = _this.mySeat;
      let roomData = {
        image: _this.user.profileImageUrl,
        xauthid: _this.user.xauthid,
        userName: _this.user.nickName || _this.user.firstName,
        status: "busy",
        mute: "Yes"
      };
      _this.socket.emit("sitOnSeat", {
        roomId: _this.chatRoomId,
        [_this.mySeat]: roomData
      });
    },
    async unmute() {
      let _this = this;
      _this.localStream.unmuteAudio();
      _this.IAmMute = false;
      let roomData = {
        image: _this.user.profileImageUrl,
        xauthid: _this.user.xauthid,
        userName: _this.user.nickName || _this.user.firstName,
        status: "busy",
        mute: "No"
      };

      _this.socket.emit("sitOnSeat", {
        roomId: _this.chatRoomId,
        [_this.mySeat]: roomData
      });
    },
    async updateTime() {
      let _this = this;
      let spentTime = Math.abs(new Date() - _this.startTime);
      spentTime = Math.floor(spentTime / 1000 / 60);
      const user = await _this.$http.post(
        _this.serverUrl + "api/updateSeatTime",
        {
          seatTime: spentTime,
          xauthid: _this.xauthid
        }
      );
      if (user.data.status) {
        _this.startTime = "";
      }
    },
    async leftSeat(seat) {
      let _this = this;
      await _this.updateTime();

      let seatData = {
        image: "",
        status: "open",
        userName: ""
      };
      _this.socket.emit("updateUserRoomInfo", {
        chatRoomId: _this.chatRoomId,
        seatNo: "",
        socketId: _this.socket.id,
        xauthid: _this.user.xauthid
      });
      _this.socket.emit("removeUserFromSeat", {
        chatRoomId: _this.chatRoomId,
        [_this.mySeat]: seatData
      });

      _this.iamOnSeat = false;
      _this.mySeat = "";

      _this.client.setClientRole(
        "audience",
        function() {
          // console.log('setHost success audience')
        },
        function(e) {
          // console.log('setHost failed', e)
        }
      );
    },
    async letMeSit(seat) {
      let _this = this;
      _this.mySeat = seat;
      _this.startTime = new Date();
      _this.isLoading = true;
      if (_this.mySeat !== "") {
        let roomData = {
          xauthid: _this.user.xauthid,
          image: _this.user.profileImageUrl,
          status: "busy",
          userName: _this.user.nickName
            ? _this.user.nickName
            : _this.user.firstName
        };
        if (_this.user) {
          _this.socket.emit("sitOnSeat", {
            roomId: _this.chatRoomId,
            [_this.mySeat]: roomData
          });
          _this.socket.emit("updateUserRoomInfo", {
            chatRoomId: _this.chatRoomId,
            seatNo: `${_this.mySeat}`,
            socketId: _this.socket.id,
            xauthid: _this.user.xauthid
          });

          _this.client.setClientRole(
            "host",
            function() {
              // _this.addStream(_this.localStream, true);
              _this.client.publish(_this.localStream, err => {
                console.log("Publish local stream error: " + err);
              });
              _this.isLoading = false;
            },
            function(e) {
              console.log("setHost failed", e);
              _this.isLoading = false;
            }
          );

          _this.localStream.unmuteAudio();
          _this.IAmMute = false;
          _this.iamOnSeat = true;
        }
      }
      if (_this.mySeat !== "") {
        setInterval(function() {
          if (_this.localStream) {
            let audioLevel = _this.localStream.getAudioLevel();
            if (audioLevel > 0.08) {
              _this.socket.emit("speaking", {
                xauthid: _this.xauthid,
                chatRoomId: _this.chatRoomId
              });
            }
          }
        }, 1000);
      }
    },
    async sendMessage() {
      let _this = this;

      let obj = {
        senderId: this.user.xauthid,
        message: this.message,
        senderName: this.user.nickName
          ? this.user.nickName
          : this.user.firstname + " " + this.user.firstname,
        createdAt: _this.$firebase.database.ServerValue.TIMESTAMP
      };
      obj = Object.assign(
        {},
        obj,
        this.user.hasOwnProperty("profileImageUrl") && {
          senderImage: this.user.profileImageUrl
        }
      );
      if (this.message.trim().length) {
        _this.showEmoji = false;
        _this.chat91FirebaseDatabase
          .ref("/chats/" + _this.chatRoomId)
          .push()
          .set(obj);
        this.message = "";
      }
    },
    streamInit(uid, attendeeMode, videoProfile, config) {
      let defaultConfig = {
        streamID: uid,
        audio: true,
        video: true,
        screen: false
      };

      switch (attendeeMode) {
        case "audio-only":
          defaultConfig.video = false;
          break;
        case "audience":
          defaultConfig.video = false;
          defaultConfig.audio = true;
          break;
        default:
        case "video":
          break;
      }
      // console.log("%c %GORACREATESTREAM: ","color:blue;font-size:40px;",defaultConfig,config)
      let stream = AgoraRTC.createStream(merge(defaultConfig, config));
      stream.setVideoProfile(videoProfile);
      return stream;
    },
    subscribeStreamEvents() {
      let rt = this;

      rt.client.on("stream-added", function(evt) {
        let stream = evt.stream;
        rt.client.subscribe(stream, function(err) {});
      });

      rt.client.on("stream-subscribed", function(evt) {
        let stream = evt.stream;
        rt.remoteStream = stream;
        rt.addStream(stream);
      });

      rt.client.on("peer-leave", function(evt) {
        rt.removeStream(evt.uid);
      });

      rt.client.on("stream-removed", function(evt) {
        let stream = evt.stream;
        rt.removeStream(stream.getId());
      });

      rt.client.on("client-role-changed", function(evt) {});
    },
    removeStream(uid) {
      this.streamList.map((item, index) => {
        if (item.getId() === uid) {
          item.close();
          let element = document.querySelector("#ag-item-" + uid);
          if (element) {
            element.parentNode.removeChild(element);
          }
          let tempList = [...this.streamList];
          tempList.splice(index, 1);
          this.streamList = tempList;
        }
      });
    },
    addStream(stream, push = false) {
      let repeatition = this.streamList.some(item => {
        return item.getId() === stream.getId();
      });
      if (repeatition) {
        return;
      }
      if (push) {
        this.streamList = this.streamList.concat([stream]);
      } else {
        this.streamList = [stream].concat(this.streamList);
      }
    },
    switchDisplay(e) {
      if (
        e.currentTarget.classList.contains("disabled") ||
        this.streamList.length <= 1
      ) {
        return;
      }
      if (this.displayMode === "pip") {
        this.displayMode = "tile";
      } else if (this.displayMode === "tile") {
        this.displayMode = "pip";
      } else if (this.displayMode === "share") {
      } else {
        console.error("Display Mode can only be tile/pip/share");
      }
    },
    hideRemote(e) {
      if (
        e.currentTarget.classList.contains("disabled") ||
        this.streamList.length <= 1
      ) {
        return;
      }
      let list;
      let id = this.streamList[this.streamList.length - 1].getId();
      list = Array.from(
        document.querySelectorAll(`.ag-item:not(#ag-item-${id})`)
      );
      list.map(item => {
        if (item.style.display !== "none") {
          item.style.display = "none";
        } else {
          item.style.display = "block";
        }
      });
    },
    async initiateChatRoom() {
      let _this = this;
      _this.socket.emit("userSocketInfo", {
        user: _this.user,
        socketId: this.socket.id
      });
      _this.socket.emit("updateUserRoomInfo", {
        chatRoomId: _this.chatRoomId,
        seatNo: "",
        socketId: _this.socket.id,
        xauthid: _this.user.xauthid
      });
      _this.socket.emit("getRoomDetails", { roomId: _this.chatRoomId });

      _this.socket.on(`${_this.chatRoomId}`, roomInfo => {
        if (roomInfo.roomExists === true) {
          _this.chatRoom = roomInfo.roomDetails;
          _this.$store.commit("CHATROOM", _this.chatRoom);
          _this.componentKeyReward += 1;
        }
      });
      // _this.socket.emit('increaseRoomCounter', {
      //   chatRoomId: _this.channel
      // });
      this.$cookie.set("chatRoomId", _this.chatRoomId);

      _this.socket.on(`update${_this.chatRoomId}`, roomInfo => {
        if (roomInfo.roomExists === true) {
          _this.chatRoom = roomInfo.roomDetails;
        }
      });

      _this.socket.on(`leftSeat_${_this.chatRoomId}`, async data => {
        if (_this.xauthid == data.xauthid) {
          _this.leftSeat();
        }
      });
      _this.socket.on(`kickout${_this.chatRoomId}`, data => {
        if (data == _this.user.xauthid) {
          _this.mySeat = "";
          _this.IAmMute = false;
          _this.iamOnSeat = false;
          _this.closeRTC();
          _this.connection.dontCaptureUserMedia = false;
          _this.connection.join(_this.chatRoomId);
        }
      });

      _this.socket.on(`sittingRoomInfo${_this.chatRoomId}`, roomInfo => {
        if (roomInfo.roomExists === true) {
          _this.chatRoom = roomInfo.roomDetails;
          // _this.isLoading = false
          _this.$store.commit("CHATROOM", _this.chatRoom);
          _this.componentKeyReward += 1;
        }
      });

      _this.socket.on(`emoji${_this.chatRoomId}`, data => {
        _this.emojiOnSeat = true;
        _this.emojiOnSeatSrc = data.emojiUrl;
        _this.emojiXauthId = data.xauthid;
        setTimeout(function() {
          _this.$forceUpdate((_this.emojiOnSeat = false));
          _this.$forceUpdate((_this.emojiOnSeatSrc = ""));
        }, 2000);
      });

      // init AgoraRTC local client
      _this.client = AgoraRTC.createClient({ mode: _this.transcode });
      _this.client.init(_this.appId, () => {
        _this.subscribeStreamEvents();

        // alert(uid);
        _this.client.join(_this.appId, _this.channel, _this.uid, uid => {
          // create local stream
          // It is not recommended to setState in function addStream
          _this.localStream = _this.streamInit(
            uid,
            _this.attendeeMode,
            _this.videoProfile
          );
          _this.localStream.init(
            () => {
              _this.client.setClientRole(
                "audience",
                function() {
                  // console.log('setHost success')
                },
                function(e) {
                  // console.log('setHost failed', e)
                }
              );
              _this.isLoading = false;
            },
            err => {
              // console.log('getUserMedia failed', err)
              // _this.readyState = true
              _this.back();
            }
          );
        });
        // _this.isLoading = false
      });

      _this.socket.on(`speaking_${_this.chatRoomId}`, speakInfo => {
        _this.$forceUpdate((_this.speakingHash[speakInfo.xauthid] = true));
        setTimeout(
          function(e) {
            _this.$forceUpdate((_this.speakingHash[e] = false));
          },
          1000,
          speakInfo.xauthid
        );
      });

      await _this.chat91FirebaseDatabase
        .ref("chats/" + _this.chatRoomId)
        .limitToLast(20)
        .on("child_added", function(data) {
          _this.messages.push(data.val());
          _this.$nextTick().then(function() {
            _this.$refs.chat_container.scrollTo(
              0,
              _this.$refs["chat_container"]["scrollHeight"]
            );
          });
        });
      let obj = {
        senderId: this.user.xauthid,
        message: "entered in room.",
        senderName: this.user.nickName
          ? this.user.nickName
          : this.user.firstname + " " + this.user.firstname,
        createdAt: _this.$firebase.database.ServerValue.TIMESTAMP,
        type: "entered"
      };
      obj = Object.assign(
        {},
        obj,
        this.user.hasOwnProperty("profileImageUrl") && {
          senderImage: this.user.profileImageUrl
        }
      );
      await _this.chat91FirebaseDatabase
        .ref("/chats/" + _this.chatRoomId)
        .push()
        .set(obj);
    }
  },

  beforeUpdate() {
    let _this = this;
    let canvas = document.querySelector("#ag-canvas");
    let no = _this.streamList.length;
    _this.streamList.map((item, index) => {
      let id = item.getId();
      let dom = document.querySelector("#ag-item-" + id);
      if (!dom) {
        dom = document.createElement("section");
        dom.setAttribute("id", "ag-item-" + id);
        dom.setAttribute("class", "ag-item");
        canvas.appendChild(dom);
        item.play("ag-item-" + id);
      }
      dom.setAttribute("style", `grid-area: _this{tile_canvas[no][index]}`);
      item.player.resize && item.player.resize();
    });
  },

  async beforeDestroy() {
    //alert('You are about to leave this group!')
    let _this = this;

    if (_this.iamOnSeat) {
      await _this.updateTime();
    }

    let seatData = {
      image: "",
      status: "open",
      userName: ""
    };

    _this.socket.emit("removeUserFromSeat", {
      chatRoomId: _this.chatRoomId,
      [_this.mySeat]: seatData
    });
    _this.socket.close();
    // _this.socket.emit('decreaseRoomCounter', {
    //   chatRoomId: _this.chatRoomId
    // })

    var data = {
      status: false,
      xauthid: ""
    };
    _this.$store.commit("ACTIVEUSERINFO", data);
    let obj = {
      senderId: this.user.xauthid,
      message: "left the room.",
      senderName: this.user.nickName
        ? this.user.nickName
        : this.user.firstname + " " + this.user.firstname,
      createdAt: _this.$firebase.database.ServerValue.TIMESTAMP,
      type: "left"
    };
    obj = Object.assign(
      {},
      obj,
      this.user.hasOwnProperty("profileImageUrl") && {
        senderImage: this.user.profileImageUrl
      }
    );
    await _this.chat91FirebaseDatabase
      .ref("/chats/" + _this.chatRoomId)
      .push()
      .set(obj);

    _this.client && _this.client.unpublish(_this.localStream);
    _this.localStream && _this.localStream.close();
    _this.client &&
      _this.client.leave(
        () => {
          // console.log('Client succeed to leave.')
        },
        () => {
          // console.log('Client failed to leave.')
        }
      );
    _this.isLoading = true;
    _this.client = null;
    _this.localStream = null;

    console.log(_this.socket);
  }
};
</script>

<style scoped>
.emoji-panel .emoji-mart {
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: auto;
  color: #000;
  border: 1px solid #d9d9d9;
  border-radius: 10px 10px 0 0;
  background: #fff;
}
</style>
