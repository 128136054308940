import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:null,
    connection:{},
    activeReward: false,
    activeFriendsList: false,
    activeUserInfo:false,
    serverUrl:'',
    seatUser:'',
    currentChatroom:{},
    seatNo:'',
    src:'',
    isShareActive: true,
    chatRooms:{},
    battleinfo: {}, // Current state of the application here
    games: {},
    contests: null,
    selected: 0,
    selectedContest: null,
    loading: true,
    userInfo: null,
    contestuserInfo: null,
    joined: false,
    spinnerLoading: false,
    carouselgames: [],
    isOpen: false,
    month: {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'Jun',
      6: 'Jul',
      7: 'Aug',
      8: 'Sept',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec'
    },
    all: [],
    weekly: [],
    weeklyTop: [],
    allTop: [],
    showTypeWeekly: true,
    wins: [],
    mahasangramInfo: {},
    // updatecontestdata:[],
    updateselectedgame: {},
    gemsHistory:[]
  },

  getters: {
    getMahaSangramInfo: state => state.mahasangramInfo,
    getActiveUserInfo: state=>state.activeUserInfo,
    serverUrl: state=> state.serverUrl,
    updateselectedgame: state => state.updateselectedgame,
    // updatecontestdata: state => state.updatecontestdata,
    battleinfo: state => state.battleinfo, // Derived computed state
    games: state => state.games,
    loading: state => state.loading,
    joined: state => state.joined,
    isOpen: state => state.isOpen,
    // status : state => state.status,
    contests: state => state.contests,
    selected: state => state.selected,
    selectedContest: state => state.selectedContest,
    userInfo: state => state.userInfo,
    contestuserInfo: state => state.contestuserInfo,
    spinnerLoading: state => state.spinnerLoading,
    carouselgames: state => state.carouselgames,
    all: state => state.all,
    weekly: state => state.weekly,
    weeklyTop: state => state.weeklyTop,
    allTop: state => state.allTop,
    wins: state => state.wins,
    mahasangraminfo: state => state.mahasangraminfo,
    getAllChatRooms: state => state.chatRooms,
    user: state => state.user,
    getseatUser: state=>state.seatUser
  },

  mutations: {
    updateServerUrl(state,data){
      state.serverUrl = data;
    },
    addgemsHistory(state,data){
      state.gemsHistory=data
    },
    updateSelectedGame(state, data) {
      // console.log('----==data==updateSelectedGame=',data,state)
      state.games[data.refId] = data.updateselectedgame
      // state.updateselectedgame = data.updateselectedgame
    },
    saveBattleInfo(state, data) {
      state.battleinfo = data.battleinfo
    },

    updateSelected(state, data) {
      state.selected = data.selected
    },

    saveGames(state, data) {
      state.games = Object.assign({},state.games,data.games);
    },

    saveCarouselGames(state, data) {
      // console.log(data)
      state.carouselgames = data.carouselgames
    },

    saveSelectedGame(state, data) {
      state.selected = data.selected
    },

    saveSelectedContests(state, data) {
      state.contests = data.contests
    },

    saveSelectedContest(state, data) {
      state.selectedContest = data.selectedContest
    },

    saveUserInfo(state, data) {
      state.userInfo = data.userInfo;
      state.user = data.userInfo
    },

    saveContestUserInfo(state, data) {
      state.contestuserInfo = data.contestuserInfo
    },
    saveMahaSangramContestInfo(state, data) {
      state.mahasangramInfo = Object.assign({},state.mahasangramInfo,data);
    },
    updateAllChatRooms(state, payload){
      state.chatRooms = Object.assign({},state.chatRooms,payload);
    },
    updateUser (state, user ) {
      state.user = user
    },
    'ACTIVEREWARD': (state, payload) => {
      state.activeReward = payload

    },
    'ACTIVEFRIENDLIST': (state, payload) => {
      state.activeFriendsList = payload
    },
    'ACTIVEUSERINFO': (state, payload) => {
      state.activeUserInfo = payload.status
      state.seatUser = payload.xauthid
      state.seatNo = payload.seatNo
    },
    // 'ACTIVEHISTORY': (state, payload) => {
    //   state.activeHistory = payload
    // },
    'CHATROOM' : (state, payload) => {
      state.currentChatroom = payload
    },
    'SRC' : (state, payload) => {
      state.src = payload
    },
    'ISSHAREACTIVE' : (state, payload) => {
      state.isShareActive = payload
    },
    'SET_USER': (state, payload) => {
      state.user = payload
    }
  },

  actions: {
    setUser: ({commit, state}, payload) => {
      commit('SET_USER', payload)
      return state.user
    },
    setServerUrl(context,payload){
      context.commit('updateServerUrl',payload)
    }
    // groupchat({ commit }, { type, payload }) {
    //   return ApiCaller(' http://test3.services-money91.com/#/api/chatrooms', 'get')
    // },
  }
})

// For fetching data from server to the model
