<template>
  <div class="page_wrapper" v-if="getSelectedGame">
    <!-- v-bind:style="getBgColor(getSelectedGame.bgColor)" -->
    <div class="gaming_overlay">
      <img :src="getSelectedGame.smallIconImageUrl" />
    </div>
    <section class="game_body game-level">
      <div class="level-headpanel">
        <span class="go-back" @click="goBack">
          <img src="static/images/go-back-icon.png" />
        </span>
        <div class="game-levelimg">
          <!-- <img :src="this.getSelectedGame.iconImageUrl"> -->
          <img :src="getSelectedGame.smallIconImageUrl" />
        </div>
        <span class="textplay">Let's Play</span>
        <h2>{{ this.getSelectedGame.name }}</h2>
      </div>

      <div class="inner-pgwrap">
        <div class="game-lvlbox">
          <div class="price_head">
            <span>{{ $t("totalprice") }}</span>
            <span>{{ $t("Period") }}</span>
            <span>{{ $t("Entryfees") }}</span>
          </div>

          <!--Rendering of all the levels start-->
          <div
            id="game1"
            class="tab-pane fade in active gamelvl-list"
            v-if="selectedGameContests"
          >
            <div
              class="price_cntnt animated fadeInRight free-cnt"
              v-bind:class="{
                'mh-cnt':
                  contest.type === 'TOURNAMENT' &&
                  contest.feeType == 'GAMES_WALLET',
                hide:
                  contest.type === 'BATTLE_GAME' ||
                  (contest.type === 'TOURNAMENT' &&
                    contest.feeType == 'GEMS') ||
                  contest.type === 'MAHASANGRAM' ||
                  contest.type === 'BATTLE' ||
                  contest.type === 'BATTLE_GAME'
              }"
              v-for="(contest, id) in getSelectedGame.contests"
              :key="id"
            >
              <!--Battle seperate UI box start-->
              <div
                class="battle-box"
                v-if="contest.type === 'BATTLE'"
                @click="openBattle(contest.referenceId, id)"
              >
                <div class="game-list">
                  <div class="img-col">
                    <img src="static/images/battle-icon.png" alt="Battle" />
                  </div>
                  <div class="dtl-col">
                    <h2>{{ contest.description }}</h2>
                    <!-- <span class="text-duration">{{getTimeString(contest.startTime)}} - {{getTimeString(contest.endTime)}}</span> -->
                    <p>
                      {{ contest.currentPlayers }}/{{ contest.playersLimit }}
                    </p>
                    <span class="price-topup">
                      <span class="coins">
                        <img
                          v-if="contest.creditType == 'MCASH'"
                          src="static/images/coin.png"
                          width="15px"
                        />
                        <img
                          v-else
                          style="color:#FFEB3B"
                          src="../assets/img/playwin.png"
                          width="20px"
                        />
                        <!-- {{contest.prize}} -->
                      </span>
                    </span>
                  </div>

                  <div class="mcash-col">
                    <span class="live" v-if="contest.status == 'LIVE'">{{
                      $t("Itsgoing")
                    }}</span>
                    <span class="live" v-else>{{ $t("aboutobegin") }}</span>
                    <span class="folder_icn ">
                      <img src="/static/images/coin.png" width="15px" />
                      {{ contest.fee }}
                    </span>
                  </div>
                </div>
              </div>
              <!--Battle box seperate UI end-->

              <!--Mahasangram box seperate UI start-->
              <div
                class="mahasangram-box"
                @click="openMahasangram(contest.referenceId)"
                v-if="contest.type === 'MAHASANGRAM'"
              >
                <div class="game-list">
                  <div class="img-col">
                    <img
                      src="/static/images/mahasangram-label-img.jpg"
                      alt="Mahasangram"
                    />
                  </div>
                  <div class="dtl-col">
                    <h2>{{ contest.description }}</h2>
                    <span class="text-duration"
                      >{{ getTimeString(contest.startTime) }} -
                      {{ getTimeString(contest.endTime) }}</span
                    >
                    <p>
                      {{ contest.currentPlayers }}/{{ contest.playersLimit }}
                    </p>
                    <span class="price-topup">
                      <span class="coins">
                        <img
                          v-if="contest.feeType == 'GEMS'"
                          src="/static/images/gems_ic.svg"
                          width="20px"
                          style="color: rgb(255, 235, 59);"
                        />
                        <img
                          v-else-if="contest.feeType == 'MCASH'"
                          src="/static/images/coin.png"
                          width="15px"
                        />
                        {{ contest.prize }}
                      </span>
                    </span>
                  </div>

                  <div class="mcash-col">
                    <span class="live" v-if="contest.status === 'LIVE'">{{
                      $t("Itsgoing")
                    }}</span>
                    <span class="live" v-if="contest.status === 'SCHEDULED'">{{
                      $t("aboutobegin")
                    }}</span>
                    <span class="folder_icn">
                      <img
                        v-if="contest.feeType == 'GEMS'"
                        src="/static/images/gems_ic.svg"
                        width="15px"
                      />
                      <img
                        v-else-if="contest.feeType == 'MCASH'"
                        src="/static/images/coin.png"
                        width="15px"
                      />
                      {{ contest.fee }}
                    </span>
                  </div>
                </div>
              </div>
              <!--Mahasangram box seperate UI ends-->

              <!--Tournament level free listing starts-->
              <div
                class="freegame-panel"
                v-if="contest.type === 'TOURNAMENT' && contest.fee == 0"
              >

                <h5
                  class="custom-text"

                  {{ contest.description }}
                </h5>


                <div class="price_cntnt_lt">
                  <div class="free_gamming-img">
                    <span>
                      <img
                        v-if="contest.creditType == 'MCASH'"
                        src="/static/images/coin.png"
                      />
                      <img
                        v-else
                        style="color:#FFEB3B"
                        src="../assets/img/playwin.png"
                      />
                      <!-- {{contest.prize}} -->
                    </span>
                  </div>
                </div>

                <div
                  class="price_cntnt_rt"
                  v-if="contest.type === 'TOURNAMENT'"
                  @click="openGameDetail(contest.referenceId)"
                >
                  <div class="duration">
                    <span class="duration_txt"
                      >{{ getTimeString(contest.startTime) }} -
                      {{ getTimeString(contest.endTime) }}</span
                    >
                    <span class="score"
                      >{{ contest.currentPlayers }}/{{
                        contest.playersLimit
                      }}</span
                    >
                  </div>

                  <div class="entry_chng">
                    <a exact>
                      <span class="live" v-if="contest.status == 'LIVE'">{{
                        $t("Itsgoing")
                      }}</span>
                    </a>

                    <a exact>
                      <span
                        class="live scheduled"
                        v-if="contest.status == 'SCHEDULED'"
                        >{{ $t("aboutobegin") }}</span
                      >
                    </a>

                    <a exact>
                      <span
                        class="folder_icn"
                        v-if="contest.feeType == 'WALLET'"
                      >
                        <img src="/static/images/rupee.svg" width="21px" />
                        {{ contest.fee }}
                      </span>
                    </a>

                    <a exact>
                      <span
                        class="folder_icn"
                        v-if="contest.feeType == 'MCASH'"
                      >
                        <img src="/static/images/coin.png" width="15px" />
                        {{ contest.fee }}
                      </span>
                    </a>

                    <a exact>
                      <span class="folder_icn" v-if="contest.feeType == 'GEMS'">
                        <img src="/static/images/gems_ic.svg" width="15px" />
                        {{ contest.fee }}
                      </span>
                    </a>

                    <span class="folder_icn" v-if="contest.feeType == 'FREE'">{{
                      $t("free")
                    }}</span>
                  </div>
                </div>
              </div>
              <!--tournament level free box ui ends-->

              <!--Normal levels listing start-->
              <div class>
                <h5
                  class="custom-text"
                  v-if="
                    contest.type === 'TOURNAMENT' &&
                      (contest.feeType == 'GAMES_WALLET' || contest.feeType == 'SWALLET')
                  "
                >
                  {{ contest.description }}
                </h5>
                <div
                  class="free_gamming-img"
                  v-if="contest.type === 'TOURNAMENT' && contest.fee !== 0"
                >
                  <span>
                    <img
                      v-if="contest.creditType == 'MCASH'"
                      src="/static/images/coin.png"
                    />
                    <img
                      v-else
                      style="color:#FFEB3B"
                      src="../assets/img/playwin.png"
                    />
                    <!-- {{contest.prize}} -->
                  </span>
                </div>
              </div>

              <div
                class="price_cntnt_rt"
                v-if="
                  contest.type === 'TOURNAMENT' &&
                    (contest.feeType == 'GAMES_WALLET' || contest.feeType == 'SWALLET') &&
                    contest.fee != 0
                "
                @click="openGameDetail(contest.referenceId)"
              >
                <div class="duration">
                  <span class="duration_txt"
                    >{{ getTimeString(contest.startTime) }} -
                    {{ getTimeString(contest.endTime) }}</span
                  >
                  <span class="score"
                    >{{ contest.currentPlayers }}/{{
                      contest.playersLimit
                    }}</span
                  >
                </div>

                <div class="entry_chng">
                  <a exact>
                    <span class="live" v-if="contest.status == 'LIVE'">{{
                      $t("Itsgoing")
                    }}</span>
                    <span
                      class="live scheduled"
                      v-else-if="contest.status == 'SCHEDULED'"
                      >{{ $t("aboutobegin") }}</span
                    >
                  </a>

                  <a exact>
                    <span class="folder_icn level-status-icon">
                      <img
                        :src="
                          contest.feeType == 'GAMES_WALLET'
                            ? '/static/images/rupee.svg'
                            : contest.feeType == 'MCASH'
                            ? '/static/images/coin.png'
                            : '/static/images/shoping_wallet.svg'
                        "
                      />
                      {{ contest.fee }}
                    </span>
                  </a>
                  <span class="folder_icn" v-if="contest.feeType == 'FREE'">{{
                    $t("free")
                  }}</span>
                </div>
              </div>
              <!--Normal levels listing end-->
            </div>
          </div>

          <!--rendering of all games end-->
        </div>
      </div>
    </section>
  </div>
  <!--page wrapper end-->
</template>

<script>
export default {
  name: "GameLevel",
  data() {
    return {
      selectedGameContests: [],
      contests: null,
      selected: 0,
      loading: true,
      userInfo: null,
      joined: false,
      spinnerLoading: false,
      xauthid: null
    };
  },
  mounted() {
    var _this = this;
    this.changeLocale();
    this.$data.xauthid = this.$cookie.get("X-Auth-Id");
    _this.$data.selected = _this.$store.getters.selected;
    _this.$emit("updateContests", this.$route.params.gamerefid);
    console.log(this.getSelectedGame.contests)
  },

  methods: {
    changeLocale(locale) {
      this.$i18n.locale = this.$cookie.get("lang") || "hi";
      if (this.$cookie.get("lang") == "ml") {
        document.body.classList.add("activeMultiLang");
      }
    },

    getBgColor(gamecolor) {
      return {
        background: `linear-gradient(135deg,${gamecolor}, ${gamecolor}, transparent, ${gamecolor})`
      };
    },

    goBack() {
      this.$router.push("/");
    },

    openGameDetail(refId) {
      var url = `/detail/${this.$route.params.gamerefid}/${refId}`;
      this.$router.push(url);
    },

    openMahasangram(refId) {
      var url = `/mahasangram/${this.$route.params.gamerefid}/${refId}`;
      this.$router.push(url);
    },

    openBattle(refId) {
      var url = `/battle/${this.$route.params.gamerefid}/${refId}`;
      this.$router.push(url);
    },

    clearSelected() {
      this.$data.selectedContest = null;
      this.$data.userInfo = null;
      this.$data.joined = false;
      this.$data.showModal = false;
    },
    getHomeData() {
      this.$http.get(constants.CONTESTSURL).then(
        function(data) {},
        response => {}
      );
    },

    getTimeString(time) {
      var _ = new Date(time);
      var min =
        _.getMinutes() < 10 ? "0" + _.getMinutes() : "" + _.getMinutes();
      return _.getHours() + ":" + min;
    },
    remainingTimeString(time) {
      var a = time;
      var b = new Date().getTime();
      var distance = a - b;
      var hrs = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var sec = Math.floor((distance % (1000 * 60)) / 1000);
      return hrs + " Hours " + min + " Minutes " + sec + " Secs";
    }
  },
  computed: {
    getSelectedGame: function() {
      return this.$store.getters.games[this.$route.params.gamerefid];
      this.$forceUpdate();
      // return this.$store.getters.updateselectedgame
    },
    getWalletGames: function() {
      var _ = this.$store.getters.games[this.$route.params.gamerefid];
      console.log(_);
      _ = _.contests.filter(function(data) {
        console.log(data);
        if (data["feeType"] == "GAMES_WALLET") return data;
      });
      return _;
    }
  }
};
</script>

<style>
.hide {
  display: none !important;
}
.all-game-modal {
  display: block !important;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(2.1);
  transform: scale(2.1);
}

.slide-fade-enter-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.loader-style-default {
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 118px);
  z-index: 9999;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
 {
  transform: translateX(50px);
  opacity: 0;
}
</style>

<style></style>
