import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import VueCookie from 'vue-cookie'
import VueResource from 'vue-resource'
import Notifications from 'vue-notification'
import VueSpinners from 'vue-spinners'
import VuePageTransition from 'vue-page-transition'
import i18n from './i18n'
import './assets/css/card.css'
import './assets/css/animated.css'
import './assets/css/modal.css'
import './assets/css/style.css'
import './assets/css/style2.css'
import './assets/css/animations.css'
import 'vue2-toast/lib/toast.css';
import Toast from 'vue2-toast'
import lozad from 'lozad'
export const lozadObserver = lozad();
import '../src/assets/css/swipe_slide.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.component('loading',Loading);
import firebase from '@firebase/app'
import '@firebase/database'
import '@firebase/auth'
Vue.prototype.$firebase= firebase;
if(window.location.hostname == "localhost"){
  store.dispatch('setServerUrl','http://localhost:5001/');
}else{
  store.dispatch('setServerUrl','https://groups.money91.com/');
}

window.__native_money91 = {}
window._AI = {}
Vue.config.productionTip = false

Vue.use(Toast, {
  defaultType: 'center',
  duration: 2000,
  wordWrap: true,
  width: '400px'
});

Vue.use(VueCookie);
Vue.use(VueResource);
Vue.use(Notifications);
Vue.use(VueSpinners);
Vue.use(VuePageTransition);

import constants from "./constants";
const config = {
    apiKey: 'AIzaSyAe8pRAGGMUBOplBnnVa0lnrLkxFO1Mkk4',
    authDomain: 'coupleapp-1154-61af0.firebaseapp.com',
    databaseURL: constants.FIREBASE_DATABASE_URL,
    storageBucket: 'bucket-gpl.appspot.com',
    projectId: 'coupleapp-1154-61af0'
};

const gplFirebase = firebase.initializeApp(config);

const chat91Config = {
  apiKey: "AIzaSyAsrsnghOiu56OzzcPbP9V93ERiNeNt5j4",
  authDomain: "groups91-2ac37.firebaseapp.com",
  databaseURL: "https://groups91-2ac37.firebaseio.com",
  projectId: "groups91-2ac37",
  storageBucket: "groups91-2ac37.appspot.com",
  messagingSenderId: "1003498859569",
  appId: "1:1003498859569:web:64e3df85944d820b09b910",
  measurementId: "G-GZGRELZX0G"
};

export const chat91Firebase = firebase.initializeApp(chat91Config,"chat91");



Vue.prototype.database = firebase.database()

const leaveUrlHeaders= ['https://api.branch.io/v1/url']
Vue.http.interceptors.push((request, next) => {
    if(leaveUrlHeaders.indexOf(request.url) < 0){
      request.headers.set('X-Auth-Id', VueCookie.get('X-Auth-Id'))
      request.headers.set('X-Auth-Token', VueCookie.get('X-Auth-Token'))
      request.headers.set('Accept', 'application/json')
      request.headers.set('lang', VueCookie.get('lang'))
      // request.headers.set('source', 'GPL')
      request.headers.set('MINI-APP-Id', '149')
    }
    next()
})



new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
