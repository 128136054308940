<template>
  <div id="app">
    <div v-if="!Object.keys(games).length" class="overlay-loader">
      <div class="loader-spin">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>

    <div class="page_wrapper" style="background:white">
      <!--Side navigation closer-->
      <div class="side-overlaybg" @click="slideLeftclose"></div>

      <!--Side navigation opener-->
      <div class="side-leftpanel">
        <span class="close-slide" @click="slideLeftclose">X</span>
        <div v-if="userInfo !== null" class="profile-detail">
          <span class="user-icon" @click="slideLeftopen">
            <img
              v-if="userInfo.profileImageUrl"
              :src="userInfo.profileImageUrl"
            />
            <img v-else src="/static/images/user-profile.svg" />
          </span>
          <span class="user-name">{{
            userInfo.firstName ? `${userInfo.firstName}` : userInfo.nickName
          }}</span>
        </div>

        <ul class="custom-detail">
          <!-- <li>
            <div class="user-cash">
              <img src="/static/images/wallet-icon.png" class="cash-icon" />
              {{ $t("wallet") }}
            </div>
            <div class="cash-value">{{ userInfo.balance }}</div>
          </li>
          <li>
            <div class="user-cash">
              <img src="/static/images/mcash-icon.png" class="cash-icon" />
              MPoints
            </div>
            <div class="cash-value">{{ userInfo.boardCoin }}</div>
          </li>-->
          <li @click="openWallet">
            <div class="user-cash">
              <img src="/static/images/rupee.svg" class="cash-icon" />
              <!-- {{ $t("gems") }} -->
            </div>
            <div v-if="userInfo" class="cash-value">{{ userInfo.gamesWalletBalance }}</div>
          </li>
        </ul>
      </div>

      <vue-page-transition name="slide">
        <router-view
          @switchOffListener="switchOffLastListener"
          @updateContests="updateGameContests($event)"
        ></router-view>
      </vue-page-transition>

      <!--v-on:switchOffListener="switchOffLastListener($event)"-->

      <footer
        class="footer game_footer wdth100"
        :style="{
          display: COMPONENTSLIST.includes(this.$route.name) ? 'none' : 'block'
        }"
      >
        <ul class="footer_list wdth100">
          <li :class="{ active: this.$route.path == '/' }">
            <router-link to="/" class="fan">
              <span class="nav_title">{{ $t("home") }}</span>
            </router-link>
          </li>
          <li :class="{ active: this.$route.path == '/mytournaments' }">
            <router-link to="/mytournaments" class="myjoined">
              <span class="nav_title">{{ $t("Playing") }}</span>
            </router-link>
          </li>
          <li :class="{ active: this.$route.path == '/tournament' }">
            <router-link to="/tournament" class="cross" exact>
              <span class="nav_title">{{ $t("mytournaments") }}</span>
            </router-link>
          </li>
          <li :class="{ active: this.$route.path == '/leaderboard' }">
            <router-link to="/leaderboard" class="star" exact>
              <span class="nav_title">{{ $t("Leaderboard") }}</span>
            </router-link>
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { COMPONENTSLIST } from "./utils/helper";
import getUserInfoMixin from "./mixins/getUserInfo";
import slideOpenClose from "./mixins/slideOpenClose";
export default {
  name: "App",
  data() {
    return {
      COMPONENTSLIST,
      games: {},
      contests: null,
      selected: 0,
      selectedContest: null,
      loading: true,
      joined: false,
      spinnerLoading: false,
      carouselgames: [],
      isOpen: false,
      user: {
        profileImageUrl: "",
        balance: 0,
        boardCoin: 0
      },
      lastRefToListen: null
    };
  },
  mixins: [getUserInfoMixin, slideOpenClose],

  async mounted() {
    let xauthid = this.$cookie.get("X-Auth-Id");
    this.getUserInfo(xauthid);
    let _this = this;
    try {
      JSBridgePlugin.setPortrait(false);
    } catch (err) {
      console.log(err);
    }
    // await this.getUserInfo(xauthid);
    let data = await _this.database.ref("gameContests").once("value");
    this.games = data.val();
    this.$store.commit("saveGames", {
      games: this.games
    });
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    openWallet(){
      JSBridgePlugin.openNativeWallet()
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }
    },
    showGemsTransactionHistory() {
      this.slideLeftclose();
      this.$router.push("/gems-transaction-history");
    },

    // slideLeftopen() {
    //   let addClassTo = (target, cls) => {
    //     document.querySelector(target).classList.add(cls);
    //   };
    //   addClassTo(".side-leftpanel", "active-slide");
    //   addClassTo(".side-overlaybg", "active");
    //   addClassTo(".page_wrapper", "page_wrapper_slide");
    // },
    //
    // slideLeftclose() {
    //   let removeClassFrom = (target, cls) => {
    //     document.querySelector(target).classList.remove(cls);
    //   };
    //   removeClassFrom(".side-leftpanel", "active-slide");
    //   removeClassFrom(".side-overlaybg", "active");
    //   removeClassFrom(".page_wrapper", "page_wrapper_slide");
    // },

    updateGameContests(refId) {
      this.database.ref(`gameContests/${refId}`).on("value", snapshot => {
        this.$store.commit("updateSelectedGame", {
          updateselectedgame: snapshot.val(),
          refId
        });
        this.lastRefToListen = refId;
        // console.log( _this.$data.lastRefToListen)
      });
    },

    switchOffLastListener(e) {
      let _this = this;
      if (this.lastRefToListen !== null) {
        this.database.ref(`gameContests/${this.lastRefToListen}`).off();
      }
    }
  }
};
</script>
<style>
@import "./assets/css/style.css";
@import "./assets/css/style2.css";

.slide-enter-active {
  transition: all 0.3s ease;
}

.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
