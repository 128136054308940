<template>
  <div class="LeaderScore">
    <!--animated fadeInRight-->
    <header class="score-board-header">
      <span>
        <img src="../assets/img/ic_back.svg" alt />
      </span>
      <span class="sb-logo">
        <img src="../assets/img/g91.svg" alt />
      </span>
      <span class="score-h-loding">Loding...</span>
      <span class="score-header-icon">
        <img src="../assets/img/stats.svg" alt="" @click="openScoreBoard" />
      </span>
    </header>

    <div class="leader-score-popup">
      <div class="popup-leaderboard">
        <ul class="score-graph">
          <li class="animated fadeInLeft">
            <div class="winner-profile">
              <span>
                <img src="../assets/img/small_user_img.png" alt />
              </span>
              <span>Akshay</span>
            </div>
            <div class="scr-rank">
              <div class="score-box">
                <span>126</span>
                <span>Score</span>
              </div>
              <img src="../assets/img/2_graph.svg" alt />
            </div>
          </li>
          <li>
            <div class="winner-profile">
              <span>
                <img src="../assets/img/user_images.png" alt />
              </span>
              <span>Akshay</span>
            </div>
            <div class="scr-rank">
              <div class="score-box">
                <span>126</span>
                <span>Score</span>
              </div>
              <img src="../assets/img/1_graph.svg" alt />
            </div>
          </li>
          <li class="animated fadeInRight">
            <div class="winner-profile">
              <span>
                <img src="../assets/img/small_user_img.png" alt />
              </span>
              <span>Akshay</span>
            </div>
            <div class="scr-rank">
              <div class="score-box">
                <span>126</span>
                <span>Score</span>
              </div>
              <img src="../assets/img/3_graph.svg" alt />
            </div>
          </li>
        </ul>
        <ul class="score-list-box">
          <li>
            <span class="win-rank">4</span>
            <span class="win-profile">
              <img src="../assets/img/small_user_img.png" alt />
            </span>
            <span class="win-name">Ankit Gupta</span>
            <div class="win-score">
              <span>90</span>
              <span>Score</span>
            </div>
          </li>
          <li>
            <span class="win-rank">5</span>
            <span class="win-profile">
              <img src="../assets/img/small_user_img.png" alt />
            </span>
            <span class="win-name">Ankit Gupta</span>
            <div class="win-score">
              <span>90</span>
              <span>Score</span>
            </div>
          </li>
          <li>
            <span class="win-rank">6</span>
            <span class="win-profile">
              <img src="../assets/img/small_user_img.png" alt />
            </span>
            <span class="win-name">Ankit Gupta</span>
            <div class="win-score">
              <span>90</span>
              <span>Score</span>
            </div>
          </li>
          <li>
            <span class="win-rank">7</span>
            <span class="win-profile">
              <img src="../assets/img/small_user_img.png" alt />
            </span>
            <span class="win-name">Ankit Gupta</span>
            <div class="win-score">
              <span>90</span>
              <span>Score</span>
            </div>
          </li>
          <li>
            <span class="win-rank">8</span>
            <span class="win-profile">
              <img src="../assets/img/small_user_img.png" alt />
            </span>
            <span class="win-name">Ankit Gupta</span>
            <div class="win-score">
              <span>90</span>
              <span>Score</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="popup-close-btn">
        <img
          src="../assets/img/cross_pop.svg"
          alt=""
          @click="closeScoreBoard"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeaderScore",
  data() {
    return {};
  },
  mounted() {},

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    closeScoreBoard() {
      document
        .querySelector(".leader-score-popup")
        .classList.remove("open_leaderscore");
    },

    openScoreBoard() {
      document
        .querySelector(".leader-score-popup")
        .classList.add("open_leaderscore");
    }
  }
};
</script>

<style>
.LeaderScore {
  width: 100%;
}
/* header css */
.score-board-header {
  width: 100%;
  position: fixed;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
}
.score-board-header .sb-logo {
  padding-left: 10px;
}
.score-board-header .score-header-icon {
  margin-left: auto;
}
.score-board-header .score-header-icon img {
  height: 18px;
}
.score-board-header .score-h-loding {
  margin: 0 auto;
  font-size: 0.84em;
  font-weight: 600;
}
/* header css end  */

/* popup css */
.LeaderScore .leader-score-popup {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  transform: translateY(100%);
  transition: all ease 0.3s;
}

.LeaderScore .leader-score-popup.open_leaderscore {
  transform: translateY(0);
}

.LeaderScore .leader-score-popup .popup-leaderboard {
  background-image: linear-gradient(
    to bottom,
    #1515bf,
    #7800a9,
    #a10092,
    #ba007d,
    #c9006b
  );
  width: 100%;
  border-radius: 15px;
  /* border: 2px solid #fff; */
  overflow: hidden;
}
.LeaderScore .leader-score-popup .popup-leaderboard .score-graph {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 40px 20px 0px;
  border-radius: 15px 15px 0 0;
  border: 2px solid #fff;
  border-bottom: none;
  margin-bottom: -8px;
}
.score-graph li {
  width: 30%;
}
.score-graph li:nth-child(2) {
  width: 40%;
}
.score-graph li:nth-child(3) .winner-profile span:nth-child(2) {
  margin-bottom: 26px;
}
.score-graph li .winner-profile {
  text-align: center;
  padding: 0 10px;
}
.score-graph li .winner-profile span {
  display: block;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  font-size: 0.8em;
  margin: 15px 0;
}
.score-graph li .winner-profile span:first-child {
  height: 42px;
  width: 42px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
  border: 2px solid #fff;
}
.score-graphli:nth-child(2) .winner-profile span:first-child {
  height: 60px;
  width: 60px;
}
.score-graph li .winner-profile span:first-child img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.score-graph li:first-child {
  margin-right: -1px;
}
.score-graph li:last-child {
  margin-left: -1px;
}
.score-graph .scr-rank {
  position: relative;
}
.score-graph .scr-rank img {
  width: 100%;
  height: 100%;
  display: block;
}
.score-graph li .scr-rank .score-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.score-graph li .scr-rank .score-box span:nth-child(2) {
  display: block;
  color: #2d2d2d;
  font-size: 0.75em;
  font-weight: 600;
}
.score-graph li:nth-child(2) .scr-rank .score-box span:nth-child(1) {
  font-size: 1.6em;
}
.score-graph li:nth-child(2) .scr-rank .score-box span:nth-child(2) {
  font-size: 0.8em;
}
.score-graph li .scr-rank .score-box span:nth-child(1) {
  font-size: 1.3em;
  font-weight: 900;
  color: #fff;
  text-shadow: 0px 2px 1px #46464673;
  margin-top: 10px;
}
.score-list-box {
  background: #fff;
  border-radius: 10px 10px 0px 0px;
  margin-top: -2px;
  position: relative;
  z-index: 2;
  max-height: 250px;
  overflow-y: auto;
}
.score-list-box li {
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #dedede;
}
.score-list-box li:last-child {
  border-bottom: none;
}
.score-list-box li .win-rank {
  background: #dbc7a4;
  border: 1px solid #b9a175;
  border-radius: 0px 10px 10px;
  height: 26px;
  padding: 0 8px;
  line-height: 26px;
}
.score-list-box li .win-profile {
  height: 30px;
  width: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 10px;
  flex-shrink: 0;
}
.score-list-box li .win-profile img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.score-list-box li .win-name {
  flex-grow: 1;
  font-weight: 700;
  color: #333333;
  font-size: 0.84em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 10px;
}
.score-list-box li .win-score span:nth-child(1) {
  display: block;
  text-align: center;
  color: #3f40cb;
  font-size: 1em;
  font-weight: 600;
}
.score-list-box li .win-score span:nth-child(2) {
  font-size: 0.7em;
  color: rgba(0, 0, 0, 60%);
  display: block;
  text-align: center;
}
.LeaderScore .leader-score-popup .popup-close-btn {
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
.LeaderScore .leader-score-popup .popup-close-btn img {
  height: 40px;
}
.score-list-box::-webkit-scrollbar {
  display: none;
}
</style>
