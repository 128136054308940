import fetch from 'cross-fetch'
import { checkStatus, parseJSON } from './responseHandler'

const constant = {
  URL: 'https://be.payboard.in/PayBoard'
}
// Make an api call

export default async (url, method = 'post', body) => {
  let headers = {
    'Content-Type': 'application/json'
  }
  return fetch(`${constant.URL}${url}`, {
    method,
    body: body ? JSON.stringify(body) : null,
    headers: headers
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(error => {
      throw error
    })
}
