import constants from "../constants";

export default {
  methods:{
    joinGame(contestId) {
      let obj = {
        referenceId: contestId
      };
      this.spinnerLoading = true;
      let grad_green_btn = document.querySelectorAll('.grad_green_btn');
      for(let el of grad_green_btn){
        el.disabled = true
      }
      this.$http.post(constants.JOINGAMEURL, obj).then(
        function(data) {
          if (data.body.requestStatus === "SUCCESS") {
            clevertap.event.push("T91_LEAGUE_JOINED", {
              description: this.$store.getters.games[this.$route.params.gameid]
                .contests[this.$route.params.contestId].description,
              fee: this.$store.getters.games[this.$route.params.gameid]
                .contests[this.$route.params.contestId].fee,
              feeType: this.$store.getters.games[this.$route.params.gameid]
                .contests[this.$route.params.contestId].feeType
            });
            this.$data.joined = true;
            this.updateContestInfo();
            this.spinnerLoading = false;
            for(let el of grad_green_btn){
              el.disabled = false;
            }
          } else {
            this.spinnerLoading = false;
            for(let el of grad_green_btn){
              el.disabled = false;
            }
            this.$notify({
              group: "low-mcash",
              text: data.data.failMessage
            });
          }
        },
        response => {
          this.spinnerLoading = false;
          for(let el of grad_green_btn){
            el.disabled = true;
          }
          this.$notify({
            group: "other-error",
            text: "Something went wrong"
          });
        }
      );
    },

  }
}
