import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/Home";
import GemsTransactionHistory from "@/components/GemsTransactionHistory";
import LeaderScore from "@/components/LeaderScore";
import Tournament from "@/components/Tournaments";
import LeaderBoard from "@/components/LeaderBoard";
import OpponentScreen from "@/components/OpponentScreen";
import WinnerScreen from "@/components/WinnerScreen";
import Joined from "@/components/Joined";
import NewChatRoom from "./components/groups91/NewChatRoom";
import Details from "@/components/Details";
import PlayGame from "@/components/PlayGame";

// import Carousel from '@/components/Carousel'
import Mahasangram from "@/components/Mahasangram";
import Battle from "@/components/Battle";
import BattleSearch from "@/components/BattleSearch";
import GameLevel from "@/components/GameLevel";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path:"/play-game",
      name:'PlayGame',
      component: PlayGame,
      props:true
    },
    {
      path: "/new-chat-room/:id",
      name: "NewChatRoom",
      component: NewChatRoom
    },
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/tournament",
      name: "Tournament",
      component: Tournament
    },
    {
      path: "/leaderboard",
      name: "LeaderBoard",
      component: LeaderBoard
    },


    {
      path: "/opponent-screen/:gameid/:battleId",
      name: "OpponentScreen",
      component: OpponentScreen
    },

    {
      path: "/winner-screen",
      name: "WinnerScreen",
      component: WinnerScreen
    },

    {
      path: "/mytournaments",
      name: "Joined",
      component: Joined
    },

    {
      path: "/gems-transaction-history",
      name: "GemsTransactionHistory",
      component: GemsTransactionHistory
    },

    {
      path: "/leader-score",
      name: "LeaderScore",
      component: LeaderScore
    },
    {
      path: "/:gamerefid",
      name: "GameLevel",
      component: GameLevel
    },
    {
      path: "/detail/:gameid/:contestId/",
      name: "Details",
      component: Details
    },
    // {
    //     path: '/carousel/:index/',
    //     name: 'Carousel',
    //     component: Carousel
    // },
    {
      path: "/mahasangram/:gameid/:contestId",
      name: "Mahasangram",
      component: Mahasangram
    },
    {
      path: "/battle/:gameid/:contestId",
      name: "Battle",
      component: Battle
    },

    {
      path: "/startBattle/:index/:gameid/:battleId",
      name: "BattleSearch",
      component: BattleSearch
    }
  ]
});
