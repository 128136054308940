<template>
  <div class="gemstransaction-history animated fadeInRight">
    <div v-if="!transactionsList.length" class="overlay-loader">
      <div class="loader-spin">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
    <div class="transaction-page">
      <div class="transaction-header">
        <div class="back-icon" @click="goBack()">
          <i class="material-icons">arrow_back</i>
        </div>
        <div class="title-col">Transaction History</div>
        <div class="mpoint-col">
          <div class="gems-textbox">
            <span class="mpoint-icon">
              <img src="/static/images/games_wallet.svg" />
            </span>
            <span v-if="userInfo!==null" class="mpoint-no">{{userInfo.gamesWalletBalance}}</span>
          </div>
        </div>
      </div>
      <div class="transaction-history">
        <ul>
          <li v-for="items in transactionsList" :key="items.id">
            <div class="history-divclass" v-if="items.oldBoardcoin">
              <div class="pro-img" v-if="items.hasOwnProperty('application')">
                <img :src="items['application'].iconImageURL" />
              </div>
              <div class="history-detail">
                <span class="name-title" v-if="items.hasOwnProperty('application')">
                  <strong>{{items.application.name}}</strong>
                  {{items.action=='DEBIT'?' को आपने जॉइन किया':' से आपने कमाए'}}
                </span>
                <span class="date-time">{{dateTimeFormat(items.date)}}</span>
              </div>
              <div class="gems-transaction">
                <span
                  :class="items.action=='DEBIT'?'failed-transaction':'success-transaction'"
                >{{items.action=='DEBIT'?"-"+items.oldBoardcoin:items.oldBoardcoin}}</span>
                <span class="gems">
                  <img src="/static/images/gems_ic.svg" />
                </span>
              </div>
            </div>
          </li>
        </ul>
        <infinite-loading @infinite="infiniteHandler" spinner="bubbles">
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </div>
  </div>
  <!--page wrapper end-->
</template>

<script>
import { mapState, mapMutations } from "vuex";
import constants from "../constants";
export default {
  name: "GemsTransactionHistory",
  components: {
    InfiniteLoading : () => import('vue-infinite-loading')
  },
  data() {
    return {
      startIndex: 21,
      transactionsList: [],
      monthArray: [
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "july",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec"
      ]
    };
  },
  mounted() {
    this.transactionsList = this.gemsHistory;
    try {
      this.$http
        .get(
          constants.PAYBOARDTESTURL +
            "/walletHistory?type=3&count=20"
        )
        .then(data => {
          this.transactionsList = data.body.data.walletTransactionHistory;
          this.addgemsHistory(this.transactionsList);
        });
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    ...mapState(["userInfo", "gemsHistory"])
  },
  methods: {
    infiniteHandler($state) {
      try {
        this.$http
          .get(
            constants.PAYBOARDTESTURL +
              `/walletHistory?type=3&startIndex=${this.startIndex}&count=20`
          )
          .then(data => {
            if (data.body.data.walletTransactionHistory.length) {
              this.startIndex = this.startIndex + 20;
              this.transactionsList = this.transactionsList.concat(
                data.body.data.walletTransactionHistory
              );
              this.addgemsHistory(this.transactionsList);
              $state.loaded();
            } else {
              $state.complete();
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    ...mapMutations(["addgemsHistory"]),
    dateTimeFormat(date) {
      let dateTime = new Date(date);
      return (
        dateTime.getDate() +
        " " +
        this.monthArray[dateTime.getMonth()] +
        " " +
        dateTime.getFullYear() +
        ", " +
        (dateTime.getHours() < 12
          ? dateTime.getHours()
          : dateTime.getHours() - 12) +
        ":" +
        (`${dateTime.getMinutes()}`.length == 1
          ? "0" + dateTime.getMinutes()
          : dateTime.getMinutes()) +
        " " +
        (dateTime.getHours() < 12 ? "A.M." : "P.M.")
      );
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
