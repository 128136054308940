<template>
  <section class="game_body myTournamentbody">
    <div class="overlay-loader" v-if="(this.hideoverlay = false)">
      <div class="loader-spin">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
    <div class="main-screen">
      <div class="inner-container">
        <div v-if="userInfo!==null" class="user-headpanel home_head">
          <div class="textlogo">
            <img src="/static/images/logo_g91.svg" />
          </div>

          <div class="mcashCol">
            <img src="/static/images/games_wallet.svg" class="mcoin-icon" />
            {{ userInfo.gamesWalletBalance }}
          </div>

          <span class="user-icon" @click="slideLeftopen">
            <img v-if="user.profileImageUrl" :src="userInfo.profileImageUrl" />
            <img v-else src="/static/images/profile_ic.svg" />
          </span>


        </div>

        <!-- <div class="user-headpanel">
          <span class="user-icon" @click="slideLeftopen">
            <img :src="user.profileImageUrl" v-if="user.profileImageUrl" />
            <img src="/static/images/user-profile.svg" v-else />
          </span>
          <div class="title">{{ $t('Playing') }}</div>

          <router-link to="/chat-group-detail" class="chat-icon" exact>
            <img src="/static/images/chat-icon.png" />
          </router-link>
        </div>-->

        <div class="home-gamelistbox">
          <div class="playing_game animated fadeInRight">
            <h4>
              <img src="/static/images/star_ic.svg" alt />
              {{ $t('Youareplayingagame') }}
              <span>({{ games.length }})</span>
            </h4>
            <div class="games_grid_innner">
              <ul class="join-game-list">
                <li v-for="(win, index) in games">
                  <div class="game-imgbox" @click="openGameLevel(win)">
                    <img class="lozad" src="../assets/images/gray_image.jpg" :data-src="win.game.smallIconImageUrl" />
                    <!-- <img src="/static/images/game-demo.jpg"> -->
                    <div class="title-block">
                      <!--v-bind:style="getPanelColor(win.game.bgColor)"-->
                      <h2 class="game-title">{{ win.game.description }}</h2>
                      <span>
                        {{
                        win.contest.description
                        }}
                      </span>
                      <!-- <span class="palyer-counter">12350 Play</span> -->
                    </div>
                    <!-- <div class="loader-overlay">
        <span class="loading-container">Loader...</span>
                    </diV>-->
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import constants from "../constants";
import {mapGetters} from "vuex";
import getUserInfo from "../mixins/getUserInfo";
import slideOpenClose from "../mixins/slideOpenClose";
import {lozadObserver} from "../main";

export default {
  name: "Joined",
  data() {
    return {
      hideoverlay: false,
      user: {
        profileImageUrl: "",
        balance: 0,
        boardCoin: 0
      },
      games: [],
      contests: null,
      selected: 0,
      selectedContest: null,
      loading: true,
      // userInfo: null,
      joined: false,
      month: {
        0: "Jan",
        1: "Feb",
        2: "Mar",
        3: "Apr",
        4: "May",
        5: "Jun",
        6: "Jul",
        7: "Aug",
        8: "Sept",
        9: "Oct",
        10: "Nov",
        11: "Dec"
      },
      bgColors: {},
      gameImages: {}
    };
  },
  mixins:[getUserInfo, slideOpenClose],
 async mounted() {
    let _this = this;
    let xauthid =  this.$cookie.get("X-Auth-Id");
    this.changeLocale();
    setInterval(function() {
      this.hideoverlay = true;
    }, 5000);
    this.$http.get(constants.CONTESTSURL + "joined?wallet=GAMES_WALLET").then(
      function(data) {
        this.$data.games = data.body.contests;
        this.$nextTick().then(()=>{
          lozadObserver.observe();
        })
      },
      response => {
        //console.log(response);
      }
    );
    this.$data.hideoverlay = false;
    this.getUserInfo(xauthid);
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = this.$cookie.get("lang") || "hi";
      if (this.$cookie.get("lang") == "ml") {
        document.body.classList.add("activeMultiLang");
      }
    },
    getPanelColor(gamecolor) {
      return {
        background: `linear-gradient(90deg, ${gamecolor}, ${gamecolor}, ${gamecolor}, transparent)`
      };
    },



    playGame() {
      if (window) {
        window.scrollTo(0, 0);
      }
      this.$http
        .post(
          constants.CONTESTSURL +
            `${this.$data.selectedContest.referenceId}/player`
        )
        .then(
          function(data) {
            //console.log();
            window.location.href = data.body.game.url;
          },
          response => {
            //console.log(response);
          }
        );
    },
    clearSelected() {
      this.$data.selectedContest = null;
      // this.$data.userInfo = null;
      this.$data.joined = false;
    },
    openGameLevel(game) {
      let _this = this;
      //find game index from store
      //create url and update selected in store
      //open url

      let g = this.$store.getters.games;
      let url = "/detail/";
      let turl = "/mahasangram/";

      for (var _ in g) {
        if (
          g[_].name === game.game.name &&
          game.contest.type === "TOURNAMENT"
        ) {
          url += game.game.referenceId + "/" + game.contest.referenceId;
          // console.log(url);
          // store.commit('updateSelected',{
          //   'selected':_
          // })
          this.$router.push(url);
          return;
        } else {
          if (
            g[_].name === game.game.name &&
            game.contest.type === "MAHASANGRAM"
          ) {
            turl += _ + "/" + game.contest.referenceId;
            _this.$store.commit("updateSelected", {
              selected: _
            });
            this.$router.push(turl);
            return;
          }
        }
      }
    },
    getTimeDate(time) {
      var _ = new Date(time);
      return (
        _.getHours() +
        ":" +
        _.getMinutes() +
        " " +
        _.getUTCDate() +
        " " +
        this.$data.month[_.getUTCMonth()]
      );
    },
    getMyWin() {
      this.$http.get(constants.CONTESTSURL + "wins/0").then(
        function(data) {
          //console.log(data);
          this.$data.wins = data.body.winners;
        },
        response => {
          //console.log(response);
        }
      );
    },
    remainingTimeString(time) {
      var a = time;
      var b = new Date().getTime();

      var distance = a - b;

      console.log(new Date());

      var hrs = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var sec = Math.floor((distance % (1000 * 60)) / 1000);

      return hrs + " Hours " + min + " Minutes " + sec + " Secs";
    },
    getTimeString(time) {
      var _ = new Date(time);
      return _.getHours() + ":" + _.getMinutes();
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
