export default {
  methods:{
    slideLeftopen() {
      let addClassTo = (target, cls) => {
        document.querySelector(target).classList.add(cls);
      };
      addClassTo(".side-leftpanel", "active-slide");
      addClassTo(".side-overlaybg", "active");
      addClassTo(".page_wrapper", "page_wrapper_slide");
    },

    slideLeftclose() {
      let removeClassFrom = (target, cls) => {
        document.querySelector(target).classList.remove(cls);
      };
      removeClassFrom(".side-leftpanel", "active-slide");
      removeClassFrom(".side-overlaybg", "active");
      removeClassFrom(".page_wrapper", "page_wrapper_slide");
    },
  }
}
