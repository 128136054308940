var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user_follow_detail"},[_c('div',{staticClass:"user_info_head"},[_c('i',{staticClass:"material-icons closePop",on:{"click":function($event){return _vm.toggleUserInfo()}}},[_vm._v("clear")]),_c('div',{staticClass:"user_img"},[(_vm.seatUserData.profileImageUrl)?_c('img',{attrs:{"src":_vm.seatUserData.profileImageUrl}}):_c('img',{staticClass:"bor-Circle",attrs:{"src":require("../../assets/images/avtar.png")}})]),_c('h3',[_vm._v(_vm._s(_vm.replcePhoneWithAsterik(_vm.seatUserData.nickName)))]),_c('h5')]),_c('div',{staticClass:"follow_action"},[(_vm.seatUserData.xauthid !== _vm.user.xauthid)?_c('span',[(_vm.follow)?_c('button',{staticClass:"btn btn-follow",on:{"click":function($event){return _vm.followMe()}}},[_vm._v("Follow")]):_c('button',{staticClass:"btn btn-follow"},[_vm._v("Following")])]):_vm._e(),(_vm.seatUserData.xauthid == _vm.user.xauthid)?_c('button',{staticClass:"btn btn-follow",on:{"click":function($event){return _vm.leftSeat()}}},[_vm._v("Leave Seat")]):_vm._e(),_c('button',{staticClass:"btn btn-sendgift",on:{"click":function($event){return _vm.viewNativeProfile()}}},[_vm._v("View Profile")])]),(_vm.currentChatroom.xauthid == _vm.user.xauthid)?_c('div',{staticClass:"actionState"},[(
        _vm.seatNo &&
        _vm.currentChatroom.userId === _vm.user.uid &&
        _vm.currentChatroom.isDefault === false &&
        _vm.currentChatroom[_vm.seatNo].mute === 'No' &&
        _vm.currentChatroom[_vm.seatNo].xauthid !== _vm.currentChatroom.xauthid
      )?_c('div',{staticClass:"stateCol",on:{"click":function($event){return _vm.muteUser()}}},[_vm._m(0),_c('span',{staticClass:"textTitle"},[_vm._v("Turn Off")])]):_vm._e(),(
        _vm.seatNo &&
        _vm.currentChatroom.userId == _vm.user.uid &&
        _vm.currentChatroom.isDefault === false &&
        _vm.currentChatroom[_vm.seatNo].mute === 'Yes' &&
        _vm.currentChatroom[_vm.seatNo].xauthid !== _vm.currentChatroom.xauthid
      )?_c('div',{staticClass:"stateCol",on:{"click":function($event){return _vm.unmuteUser()}}},[_vm._m(1),_c('span',{staticClass:"textTitle"},[_vm._v("Turn on")])]):_vm._e(),(
    _vm.seatNo &&
    _vm.currentChatroom.userId === _vm.user.uid &&
    _vm.currentChatroom.isDefault === false &&
    _vm.currentChatroom[_vm.seatNo].xauthid !== _vm.currentChatroom.xauthid
  )?_c('div',{staticClass:"stateCol",on:{"click":function($event){return _vm.kickOut()}}},[_vm._m(2),_c('span',{staticClass:"textTitle"},[_vm._v("Kick out")])]):_vm._e()]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"stateIcon"},[_c('i',{staticClass:"material-icons"},[_vm._v("mic")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"stateIcon"},[_c('i',{staticClass:"material-icons"},[_vm._v("mic_off")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"stateIcon"},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])])}]

export { render, staticRenderFns }