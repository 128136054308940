<template>
  <div class="gameplay-cnt">
    <div class="header_2">
      <header>
        <div class="left-info">
          <span @click="$router.go(-1)" class="icon-Btn goback">
            <img src="/static/images/go-back-icon-blk.png" />
          </span>

          <div class="rgt-navtab">
            <agora-plugin :channel-id="$route.query.contestId"></agora-plugin>
            <span
              @click="showLeaderBoard = !showLeaderBoard"
              class="icon-Btn leader-baricon"
            >
              <img src="@/assets/img/stats.svg" />
            </span>
          </div>
        </div>
      </header>

      <div
        class="playleadermodal modallayout "
        :class="{ openlayout: showLeaderBoard }"
      >
        <div class="leader-detail">
          <span
            @click="showLeaderBoard = !showLeaderBoard"
            class="icon-Btn close-leaderboard"
          >
            <close-icon />
          </span>
          <in-game-leader-board v-if="showLeaderBoard"></in-game-leader-board>
        </div>
      </div>
    </div>
    <div>
      <iframe
        class="game-container"
        :src="decodeURIComponent($route.query.gameUrl)"
      ></iframe>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
// Vue.use(window.agoraPlugin);
import InGameLeaderBoard from "@/components/InGameLeaderBoard";
import CloseIcon from "@/components/svgicons/CloseIcon";
export default {
  name: "PlayGame",
  components: {
    InGameLeaderBoard,
    CloseIcon
  },
  data() {
    return {
      showLeaderBoard: false
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/leader-board.scss";
.game-container {
  width: 100%;
  padding: 0;
  border: 0;
  outline: none;
  height: calc(100vh - 30px);
}
</style>
