export const COMPONENTSLIST = [
  'Details',
  'GameLevel',
  'Battle',
  'NewChatRoom',
  'Mahasangram',
  'BattleSearch',
  'ChatGroupListing',
  'ChatGroupDetail',
  'OpponentScreen',
  'GemsTransactionHistory',
  'LeaderScore',
  'PlayGame'
]
