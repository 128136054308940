<template>
  <section class="game_body battle_runner_screen">
    <div class="noPlayerFound-box" v-if="this.$data.noplayerfound === true">
      <p>{{ this.$data.battleinfo.successMessage }}</p>
      <span class="battle-trybtn" @click="goBack">{{ $t('Tryagain') }} </span>
    </div>
    <div v-if="this.getBattleInfo.user">
      <div class="battle_start" v-if="this.$data.partnerFound === false">
        <div class="battle_icon_image runner1">
          <div class="left_icon">
            <div class="game_image_box ">
              <img
                src="/static/images/battle_user.png"
                class="img-circle"
                v-if="this.getBattleInfo.user.profileImageUrl === ''"
              />
              <img
                :src="this.getBattleInfo.user.profileImageUrl"
                class="img-circle"
                v-if="this.getBattleInfo.user.profileImageUrl !== ''"
              />
            </div>
            <span class="initial-name">{{ this.getBattleInfo.user.name }}</span>
          </div>
          <div class="center_icon">
            <img src="/static/images/battle-icon.png" />
          </div>
          <div class="right_icon animated pulse infinite">
            <div class="game_image_box ">
              <img src="/static/images/battle_user.png" class="img-circle" />
            </div>
          </div>
        </div>
      </div>
      <div class="battle_start_icon" v-if="this.$data.partnerFound === false">
        <p class="finding">{{ $t('Finding') }}</p>
        <div id="spinner_loader" v-if="this.$data.noplayerfound === false">
          <img src="/static/images/2.gif" width="30px" />
        </div>
      </div>

      <span
        class="battle-trybtn"
        @click="goBack"
        v-if="this.$data.battleinfo.status === 'COMPLETED'"
        >{{ $t('Playagain') }}</span
      >

      <div v-if="this.$data.partnerFound === true && this.getBattleInfo.user">
        <span
          class="opponent_success"
          v-if="getBattleInfo.status === 'CONNECTED'"
          >{{ $t('Opponentgot') }}</span
        >
        <span
          class="opponent_success"
          v-if="getBattleInfo.status === 'PLAYING'"
          >{{ $t('Youropponent') }}</span
        >
        <div class="battle_start">
          <div class="battle_icon_image">
            <div class="left_icon">
              <div class="game_image_box">
                <img
                  :src="getBattleInfo.user.profileImageUrl"
                  class="img-circle"
                  v-if="getBattleInfo.user.profileImageUrl !== ''"
                />
                <img
                  src="/static/images/battle_user.png"
                  class="img-circle"
                  v-if="getBattleInfo.user.profileImageUrl === ''"
                />
              </div>
              <div class="game_user_detail">
                <p>{{ getBattleInfo.user.name }}</p>
                <!-- <span class="user-badges gold"><em></em>Gold</span> -->
                <p>{{ $t('score') }}</p>
                <span>{{ getBattleInfo.user.score }}</span>
              </div>
            </div>
            <div class="center_icon">
              <img src="/static/images/battle-icon.png" />
            </div>
            <div class="right_icon animated tada">
              <div class="game_image_box">
                <img
                  :src="getBattleInfo.opponent.profileImageUrl"
                  class="img-circle"
                  v-if="getBattleInfo.opponent.profileImageUrl !== ''"
                />
                <img
                  src="/static/images/battle_user.png"
                  class="img-circle"
                  v-if="getBattleInfo.opponent.profileImageUrl === ''"
                />
              </div>
              <div class="game_user_detail">
                <p>{{ getBattleInfo.opponent.name }}</p>
                <p>{{ $t('score') }}</p>
                <span>{{ getBattleInfo.opponent.score }}</span>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="grad_green_btn"
          @click="playGame"
          v-if="this.$data.battleinfo.status === 'PLAYING'"
        >
          {{ $t('Play') }}
        </button>
        <span class="processbattle" v-if="getBattleInfo.status === 'CONNECTED'">
          {{ $t('Yourgamebegin') }}
        </span>
        <div v-if="this.$data.battleinfo.status === 'COMPLETED'">
          {{ $t('Gameendedrank') }} {{ getBattleInfo.user.rank }}
          {{ $t('competitorrank') }} {{ getBattleInfo.opponent.rank }} है |
        </div>
        <div class="battle-duration">
          {{ $t('Battle') }} {{ getTime(getBattleInfo.startTime) }}
          {{ $t('From') }} {{ getTime(getBattleInfo.endTime) }}
          {{ $t('Willruntill') }}
        </div>
      </div>
    </div>
  </section>
</template>

<!--<template>
 <section class="game_body opponent-panel">
<div class="main-screen">
  <div class="inner-container">

<div class="opponent-screenbx">
  <span class="go-back" @click="goBack()"><img src="/static/images/go-back-icon.png"></span>
  <span class="close-opponent" @click="goBack()">X</span>
  <div class="inner-opponentbx">
<h2>2 Players</h2>
<div class="opponent-content">
<div class="title-block">
  <h3>SHSEEP FIGHT</h3>
</div>

<div class="text-counter">Matching …. 5s</div>

<div class="opponent-playerbox">
  <div class="player-col">
    <span class="player-icon">
      <img :src="getBattleInfo.user.profileImageUrl" v-if='getBattleInfo.user.profileImageUrl!==""'>
      </span>
    <span class="player-name">{{getBattleInfo.user.name}}</span>
  </div>
  <div class="player-col">
 <span class="player-icon opponent-player">

<div class="loader-spin" v-if="this.$data.battleinfo.status === 'SEARCHING'">
  <div class="bounce1"></div>
  <div class="bounce2"></div>
  <div class="bounce3"></div>
</div>
 </span>

  <span class="player-icon opponent-player" v-if="this.$data.battleinfo.status === 'CONNECTED'">
    <img :src="getBattleInfo.opponent.profileImageUrl" v-if='getBattleInfo.opponent.profileImageUrl!==""'>
 </span>


  <span class="player-name">{{getBattleInfo.opponent.name}}</span>
    </div>
    <button type="button" class="grad_green_btn" @click="playGame" v-if="this.$data.battleinfo.status==='PLAYING'">खेलें</button>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
</template>-->

<script>
import constants from '../constants'
export default {
  name: 'BattleSearch',
  data() {
    return {
      games: [],
      contestuserinfo: {},
      contests: null,
      selected: 0,
      selectedContest: null,
      loading: false,
      userInfo: null,
      joined: true,
      spinnerLoading: false,
      carouselgames: [],
      isOpen: false,
      showModal: false,
      chats: [],
      chatMsg: '',
      contestScore: [],
      battleinfo: {},
      partnerFound: false,
      noplayerfound: false,
      successMessage: ''
    }
  },
  mounted() {
    var _this = this
    this.changeLocale()
    var xauthid = this.$cookie.get('X-Auth-Id')
    var url =
      'user_battle_status/' +
      xauthid +
      '/games/' +
      this.$route.params.gameid +
      '/battles/' +
      this.$route.params.battleId
    console.log(url)
    this.database.ref(url).on('value', function(snapshot) {
      if (snapshot.val() != null) {
        _this.$data.battleinfo = snapshot.val()
        if (
          _this.$data.battleinfo.status == 'CONNECTED' ||
          _this.$data.battleinfo.status == 'PLAYING'
        ) {
          _this.$data.partnerFound = true
          _this.$data.noplayerfound = false
        } else if (_this.$data.battleinfo.status == 'SEARCHING') {
          _this.$data.noplayerfound = false
        } else {
          _this.$data.noplayerfound = true
        }
      } else {
        _this.$http
          .get(constants.BATTLEURL + _this.$route.params.battleId)
          .then(
            function(data) {
              console.log('battles', data)
              _this.$data.battleinfo = data.body
              _this.$data.noplayerfound = true
              if (_this.$data.battleinfo.status === 'MATCH_NOT_FOUND') {
                console.log(_this.$data.battleinfo.successMessage)
                _this.$data.successMessage =
                  _this.$data.battleinfo.successMessage
              } else if (this.$data.battleinfo.status === 'COMPLETED') {
                console.log(_this.$data.battleinfo.successMessage)
                _this.$data.successMessage =
                  _this.$data.battleinfo.successMessage
              }
            },
            response => {}
          )

        // window.history.go(-1)
        // window.location.href=window.location.origin+"/#/battle/"+_this.$route.params.index+"/"+_this.$route.params.battleId
      }
    })
  },

  methods: {
    changeLocale(locale) {
      this.$i18n.locale = this.$cookie.get('lang') || 'hi'
      if (this.$cookie.get('lang') == 'ml') {
        document.body.classList.add('activeMultiLang')
      }
      console.log({ lang: this.$cookie.get('lang') })
    },
    goBack() {
      var _this = this
      window.history.go(-1)
      window.location.href =
        window.location.origin +
        '/#/battle/' +
        _this.$route.params.index +
        '/' +
        _this.$route.params.battleId
    },
    getTime(timestamp) {
      var _time = new Date(timestamp).toLocaleTimeString()
      return _time
    },

    playGame() {
      if (window) {
        window.scrollTo(0, 0)
      }
      this.spinnerLoading = true
      this.$http
        .post(
          constants.CONTESTSURL +
            `${this.getBattleInfo.battle_game_reference_id}/player`
        )
        .then(
          function(data) {
            //console.log();
            window.location.href = data.body.game.url
          },
          response => {
            //console.log(response);
            this.$notify({
              group: 'low-mcash',
              text: 'Something went wrong'
            })
            this.spinnerLoading = false
            window.history.back()
          }
        )
    }
  },

  computed: {
    getBattleInfo: function() {
      console.log(this.$data.battleinfo)
      return this.$data.battleinfo
    },
    getSelectedContestid: function() {
      //Get selected contest referenceId
      if (this.$store.getters.games.length > 0) {
        if (
          this.$data.battleinfo.battle_game_reference_id in
          this.$store.getters.games[this.$route.params.index].contests
        )
          return this.$store.getters.games[this.$route.params.index].contests[
            this.$data.battleinfo.battle_game_reference_id
          ]
      } else {
        return null
      }
      if (
        this.$data.battleinfo.battle_game_reference_id in
        this.$store.getters.games[this.$route.params.index].contests
      )
        return this.$store.getters.games[this.$route.params.index].contests[
          this.$data.battleinfo.battle_game_reference_id
        ]
      else {
        // this.$router.go(-1)
      }
    }
  }
}
</script>
<style></style>
