<template>
  <section class="game_body tournamentBody">
    <div class="overlay-loader" v-if="loader">
      <div class="loader-spin">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>

    <div class="main-screen">
      <div class="inner-container">
        <div v-if="userInfo!==null" class="user-headpanel home_head">
          <div class="textlogo">
            <img src="static/images/logo_g91.svg" />
          </div>

          <div class="mcashCol">
            <img src="/static/images/games_wallet.svg" class="mcoin-icon" />
            {{ userInfo.gamesWalletBalance }}
          </div>

          <span class="user-icon" @click="slideLeftopen">
            <img v-if="user.profileImageUrl" :src="user.profileImageUrl" />
            <img v-else src="/static/images/profile_ic.svg" />
          </span>


        </div>
        <!-- <div class="user-headpanel">
          <span class="user-icon" @click="slideLeftopen">
            <img :src="user.profileImageUrl" v-if="user.profileImageUrl" />
            <img src="/static/images/user-profile.svg" v-else />
          </span>
          <div class="title">{{ $t('mytournaments') }}</div>
          <router-link to="/chat-group-detail" class="chat-icon" exact>
            <img src="/static/images/chat-icon.png" />
          </router-link>
        </div>-->

        <!-- <div class="winner_row wdth100 hide">
				<span>जीत</span>
				<ul class="winner_names">
				<marquee>

						<li>
							<span class="winner_name">अभय कुमार </span>
							<span class="coins_wins">200 कॉइन </span>
						</li>

						<li>
							<span class="winner_name">खान </span>
							<span class="coins_wins">600 कॉइन </span>
						</li>

				</marquee>
				</ul>
        </div>-->
        <div class="home-gamelistbox mytournament-box">
          <div class="myaccont-box">
            <h4>{{ $t('MyAccount') }}</h4>

            <div class="myaccont-inner">
              <!-- <div class="data_col">
                <img src="../assets/img/money.png" alt />
                <div class="text">
                  <span class="account-text">{{ userInfo.balance }}</span>
                  <span class="text-title">{{ $t('wallet') }}</span>
                </div>
              </div>

              <div class="data_col">
                <img src="../assets/img/m_point.png" alt />
                <div class="text">
                  <span class="account-text">{{ userInfo.boardCoin }}</span>
                  <span class="text-title">Mcash</span>
                </div>
              </div>-->

              <div class="data_col">
                <img src="/static/images/rupee.svg" alt />
                <div class="text">
                  <span class="account-text">{{ userInfo.gamesWalletBalance }}</span>
                  <span class="text-title">GAMES WALLET</span>
                </div>
              </div>
            </div>
          </div>

          <div class="tournament_comp wdth100">
            <div class="cstm_container">
              <h4>
                {{ $t('Tournamentisover') }}
                <span>{{ wins.length }}</span>
              </h4>
              <div class="tournament_row wdth100">
                <div class="tournament_col wdth100 animated fadeInUp" v-for="win in wins">
                  <div class="tournament_head">
                    <span class="tournament_game">
                      <img class="lozad"  src="../assets/images/gray_image.jpg" :data-src="win.game.iconImageUrl" />
                    </span>
                    <div class="tournament_details">
                      <h5>{{ win.game.description }}</h5>
                      <span>
                        {{ getTimeDate(win.contest.startTime) }} -
                        {{ getTimeDate(win.contest.endTime) }}
                      </span>
                    </div>

                    <div class="you_won">
                      <span class>
                        <img
                          v-if="win.userContestData.creditType === 'MCASH'"
                          src="/static/images/coin.png"
                          width="20px"
                        />
                        <img
                          v-if="win.userContestData.creditType === 'WALLET'"
                          src="/static/images/money-icon.png"
                          width="20px"
                        />
                        <img
                          v-if="win.userContestData.creditType === 'GEMS'"
                          src="../assets/img/gems_ic.svg"
                          width="20px"
                        />
                        {{ win.userContestData.prize }}
                      </span>
                      <span>{{ $t('YouWon') }}</span>
                    </div>
                  </div>
                  <div class="tournament_body wdth100">
                    <!-- <div class="tournament_cntnt">
                      <span class="strar_coins" >
                        <img v-if="win.contest.feeType == 'MCASH'" src="../assets/img/star.png" />
                        <img
                          v-else-if="win.contest.feeType == 'WALLET'"
                          src="/static/images/money-icon.png"
                        />
                        <img
                          v-else-if="win.contest.feeType == 'GEMS'"
                          src="../assets/img/gems_ic.svg"
                        />
                        {{ win.contest.prize }}
                      </span>
                      <h6>{{ $t('TotalRewards') }}</h6>
                    </div>-->
                    <div class="tournament_cntnt">
                      <span>{{ win.contest.fee }}</span>
                      <h6>{{ $t('Entryfees') }}</h6>
                    </div>
                    <div class="tournament_cntnt">
                      <span>{{ win.userContestData.rank }}</span>
                      <h6>{{ $t('Yourrank') }}</h6>
                    </div>
                  </div>
                  <!-- <div class="tournament_footer wdth100">
                    <span>{{ $t('YouWon') }}</span>
                    <span class="coins">
                      <img
                        v-if="win.userContestData.creditType === 'MCASH'"
                        src="/static/images/coin.png"
                        width="20px"
                      />
                      <img
                        v-if="win.userContestData.creditType === 'WALLET'"
                        src="/static/images/money-icon.png"
                        width="20px"
                      />
                      {{ win.userContestData.prize }}</span
                    >
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import constants from "../constants";
import { mapGetters } from "vuex";
import getUserInfo from "../mixins/getUserInfo";
import slideOpenClose from "../mixins/slideOpenClose";
import {lozadObserver} from "../main";

export default {
  name: "Tournament",
  data() {
    return {
      user: {
        profileImageUrl: "/static/images/user_profile.png",
        balance: 0,
        boardCoin: 0
      },
      loader:true,
      wins: [],
      month: {
        0: "Jan",
        1: "Feb",
        2: "Mar",
        3: "Apr",
        4: "May",
        5: "Jun",
        6: "Jul",
        7: "Aug",
        8: "Sept",
        9: "Oct",
        10: "Nov",
        11: "Dec"
      }
    };
  },
  mixins:[getUserInfo, slideOpenClose],
  mounted() {
    let xauthid= this.$cookie.get('X-Auth-Id');
    this.getUserInfo(xauthid);
    this.changeLocale();
    this.getMyWin();
    this.$data.loader=false;
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = this.$cookie.get("lang") || "hi";
      if (this.$cookie.get("lang") == "ml") {
        document.body.classList.add("activeMultiLang");
      }
    },



    getTimeDate(time) {
      var _ = new Date(time);
      return (
        _.getHours() +
        ":" +
        _.getMinutes() +
        " " +
        _.getUTCDate() +
        " " +
        this.$data.month[_.getUTCMonth()]
      );
    },
    getMyWin() {
      this.$http.get(constants.CONTESTSURL + "wins/0?wallet=GAMES_WALLET").then(
        function(data) {
          //console.log(data);
          this.$data.wins = data.body.winners;
          this.$nextTick().then(()=>{
            lozadObserver.observe()
          })
        },
        response => {
          //console.log(response);
        }
      );
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
