<template>
  <section class="mid-content" v-if="leaders">
    <div class="top-leader" v-if="leaders.length">
      <rank-card :rank="2" :winner="leaders[1] || {}"></rank-card>
      <rank-card :rank="1" :winner="leaders[0] || {}"></rank-card>
      <rank-card :rank="3" :winner="leaders[2] || {}"></rank-card>
    </div>
    <div class="leader">
      <div
        class="card-list"
        v-for="(leader, index) in getLeaders"
        :key="leader['xAuthId']"
      >
        <div class="rank">{{ index + 4 }}</div>
        <div class="leader-icon">
          <img
            :src="
              leader['profileImageUrl'] ||
                require('@/assets/images/profile.png')
            "
            alt=""
          />
        </div>
        <div class="leader-title">
          {{ leader["name"] }}
        </div>
        <div class="eraining">
          <span class="text-value">{{ leader["amount"] }}</span>
          <span class="text-title">Total Earning</span>
        </div>
      </div>
    </div>
    <div class="no-data" v-if="!isLoading && !leaders.length">
      <div class="img-box">
        <img src="@/assets/img/thumb/no_data_found.svg" />
      </div>
      <h2>No Data Found</h2>
    </div>

    <!--      <game-drawer :is-slide="isSlide" :event-bus="eventBus"></game-drawer>-->
  </section>
</template>

<script>
import constants from "@/constants";
import RankCard from "@/components/RankCard";

export default {
  name: "InGameLeaderBoard",
  components: { RankCard },
  async created() {
    await this.getLeaderBoard();
  },
  data() {
    return {
      leaders: [],
      isLoading: true
    };
  },
  computed: {
    getLeaders() {
      if (this.leaders.length < 4) return [];
      return this.leaders.slice(3, this.leaders.length - 1);
    }
  },
  methods: {
    async getLeaderBoard() {
      try {
        const response = await this.$http.get(
          `${constants.CONTESTSURL}${
            this.$route.query.contestId
          }/leaders?q=${new Date().getTime()}`
        );
        this.leaders = response.data.leaders;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
    closeScoreBoard() {
      document
        .querySelector(".leader-score-popup")
        .classList.remove("open_leaderscore");
    }
  }
};
</script>
