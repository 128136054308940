import constants from "../constants";

export default {
  methods:{
    getUserInfo: async function(xauthid){
      let data;
      let _this = this;
      let userInfoObject = {};
      let getUser = await _this.$http.get(`${constants.GETUSERINFO}`);
      // let getUserWalletInfo = await _this.$http.get(`${constants.GETUSERWALLETINFO}${xauthid}`);
      ({data: {data: {data}}} = getUser );
      userInfoObject = Object.assign({}, userInfoObject, getUser.data.data);
      // ({data: {data}} = getUserWalletInfo);
      // userInfoObject = Object.assign({}, userInfoObject, data);
      this.$data.user = userInfoObject;
      this.$store.commit("saveUserInfo", {
        userInfo: userInfoObject
      });
    }
  }
}
