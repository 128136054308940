<template>
  <section class="game-details mahasangram-details" v-if="getSelectedGame">
    <div class="gaming_details_container">
      <div class="gaming_overlay">
        <!-- -->
        <img :src="getSelectedGame.smallIconImageUrl" />
      </div>
      <div class="game_details_wrapper">
        <div class="game_header">
          <span @click="goBack">
            <img src="../assets/img/back_btn.png" alt />
          </span>
          <div class="game_title" v-if="getSelectedGame!=null">
            <h3>{{getSelectedGame.name}}</h3>
            <span class="title">{{$t('Mahasangram')}}</span>
          </div>
          <button type="button" name="button" class="share_btn" @click="share">
            <img src="../assets/img/share_earn.svg" alt />
            {{$t('share')}}
          </button>
        </div>
        <ul class="play_game">
          <li>
            <div class="gaming_img">
              <img src="../assets/img/gift.png" />
            </div>
            <div class="title">
              <h2>{{getSelectedContestid.prize}}</h2>
              <p>{{$t('TotalRewards')}}</p>
            </div>
            <button type="button" name="button" @click="isOpen=!isOpen" class="play_btn">
              <img src="../assets/img/remote.png" alt />
              {{!isOpen? $t('Howtoplay') : $t('stopit')}}
            </button>
            <div class="game_status">
              <span
                class
              >{{getSelectedContestid.currentPlayers}}/{{getSelectedContestid.playersLimit}}</span>
              <span
                class
              >{{getTimeString(getSelectedContestid.startTime)}} - {{getTimeString(getSelectedContestid.endTime)}}</span>
            </div>
          </li>
          <div id="game_video" v-if="isOpen">
            <iframe
              width="100%"
              height="90%"
              frameborder="0"
              :src="getYouTubeVideo()"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen"
              allowfullscreen
            ></iframe>
          </div>
        </ul>

        <!-- tabingSection -->
        <div class="tab_details">
          <ul class="tabs">
            <li class="nav-item" :class="currentTab==='pills-prize'?'active':''">
              <a @click="currentTab='pills-prize'"
                class="nav-link active"
                data-toggle="pill"
                href="#pills-prize"
              >{{$t('Amountdistribution')}}</a>
            </li>
            <li class="nav-item" :class="currentTab==='pills-leadboard'?'active':''">
              <a @click="currentTab='pills-leadboard'"
                 class="nav-link" data-toggle="pill"
                 href="#pills-leadboard">{{$t('Leaderboard')}}</a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade in" v-if="currentTab==='pills-prize'"
                 :class="currentTab==='pills-prize'?'active':''" id="pills-prize">
              <div class="tab-container active in">
                <div >
                  <div class="score-board-box" v-if="joined">
                    <div class="score-col">
                      <p>{{getMahaSangramInfo.playCount}} / {{getMahaSangramInfo.playLimit}}</p>
                      <h3>{{$t('haveplay')}}</h3>
                    </div>
                    <div class="score-col">
                      <p>{{getMahaSangramInfo.totalScore}}</p>
                      <h3>{{$t('totalscore')}}</h3>
                    </div>
                    <div class="score-col">
                      <p>{{getMahaSangramInfo.rank }}</p>
                      <h3>{{$t('Yourrank')}}</h3>
                    </div>
                  </div>
                  <div
                    class="mhcnt-mes play-text-message"
                    v-else-if="joined &&(getMahaSangramInfo.playCount==getMahaSangramInfo.playLimit)
                        && (getMahaSangramInfo.playCount!==0 && getMahaSangramInfo.playLimit!==0)"
                  >
                    <p>{{$t('you')}} {{getMahaSangramInfo.playCount}} बार खेल लिया है| आगे खेलने के लिए वापस महासंग्राम से जुड़े|</p>
                  </div>
                  <div class="point-distbox">
                    <h2>{{$t('Amountdistribution')}}</h2>
                    <div class="dist-list">
                      <div class="ranks" v-for="r in getSelectedContestid.ranges">
                        <h5>{{ (getSelectedContestid.hasOwnProperty('rangeType') && getSelectedContestid.rangeType === 'RANK') ||
                          !getSelectedContestid.hasOwnProperty('rangeType') ? `${$t('rank')} ${r.min} - ${r.max} `
                          : `${i === 0 ? $t('top') : $t('next')} ${r.max-r.min}%`}}</h5>
                        <span>
                          {{r.prize}}
                          <img src="../assets/img/star.png" v-if="r.creditType=='MCASH'" />
                          <img src="/static/images/gems_ic.svg" width="20px" v-else />
                        </span>
                      </div>
                    </div>
                    <div class="rule-content">
                      <h2>{{$t('Rules')}}</h2>
                      <ul>
                        <li>
                          <div class="point-num">
                            <span>01</span>
                          </div>
                          <div class="point-text">
                            <p>{{$t('Youenter')}} {{getSelectedContestid.initialPlayLimit}} {{$t('Canplay')}} {{getSelectedContestid.initialPlayLimit}} {{$t('p6')}}</p>
                          </div>
                        </li>
                        <li>
                          <div class="point-num">
                            <span>02</span>
                          </div>
                          <div class="point-text last">
                            <p>{{$t('scorefor')}}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- leader board-content start -->
            <div class="tab-pane fade"   :class="currentTab==='pills-prize'?'active':''"
                 v-if="currentTab==='pills-leadboard'" id="pills-leadboard">
              <div class="lead-inner">
                <ul>
                  <li v-for="(score,index) in this.$data.contestScore">
                    <span class="s_number">{{index+1}}</span>
                    <div class="items user-imgbox">
                      <img
                        :src="score.profileImageUrl"
                        v-if="score.profileImageUrl!==null"
                        @click="openProfile(contestScore[index].xAuthId)"
                      />
                      <img
                        src="https://d19d4vszpb8ku9.cloudfront.net/8a20cfc6-cab5-491f-91ef-0ec6903d92f3.png"
                        v-else="score.profileImageUrl===null"
                        @click="openProfile(contestScore[index].xAuthId)"
                      />
                      <!-- <span class="lead-label">{{index+1}}</span> -->
                    </div>
                    <div class="items user-name">{{score.name}}</div>
                    <div class="items user-score">
                      <span class="score_number">{{score.score}}</span>
                      <span class="score_text">{{$t('score')}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- tabing_section -->

        <!-- bottom_fix section  -->
        <div class="bottm_fix_section">
          <span
            class="last_time"
          >{{$t('LastTime')}} {{getTimeString(getSelectedContestid.joinEndTime)}} {{$t('is')}}</span>
          <div class="play_buttons">
            <button type="button" @click="joinGame($route.params.contestId)"
                    v-bind:style="{ display: (joined ? 'none': 'block') }">
              {{$t('Play')}}
              <img
                v-if="getSelectedContestid.feeType=='MCASH'"
                src="../assets/img/star.png"
              />
              <img  style="vertical-align:bottom" v-else src="../assets/img/gems_ic.svg" />
              {{getSelectedContestid.fee}} {{$t('In')}}
            </button>
            <button
              type="button"
              @click="playGame"
              class="grad_green_btn"
              v-bind:style="{ display: ( !joined ? 'none': 'block'  ) }">{{$t('Play')}}</button>
          </div>
          <div class="game_status">
            <span v-if="getSelectedContestid.status == 'SCHEDULED'">
              {{$t('Gameisstarting')}}
              <span
                class="timer"
              >{{remainingTimeString(getSelectedContestid.startTime)}}</span>
              {{$t('In')}}
            </span>
            <span v-if="getSelectedContestid.status == 'LIVE'">
              {{$t('Gameisending')}}
              <span
                class="timer"
              >{{remainingTimeString(getSelectedContestid.endTime)}}</span>
              {{$t('In')}}
            </span>
          </div>
          <div class="loading-spinner">
            <circle-spin v-if="spinnerLoading"></circle-spin>
          </div>
          <div v-if="!playersFull">{{$t('p4')}}</div>
          <notifications
            classes="vue-notification-style animated fadeInRight"
            position="top right"
            group="low-mcash"
          />
        </div>
      </div>
    </div>
    <!-- end of gaming_details_container -->
  </section>
</template>

<script>
import constants from "../constants";
import joinGame from "../mixins/joinGame";
import {mapGetters, mapState} from "vuex";
export default {
  name: "MAHASANGRAM",
  data() {
    return {
      contests: null,
      selected: 0,
      selectedContest: null,
      loading: true,
      userInfo: null,
      joined: false,
      spinnerLoading: false,
      carouselgames: [],
      isOpen: false,
      showModal: false,
      chats: [],
      chatMsg: "",
      contestScore: [],
      // mahasangraminfo: {},
      currentTab:'pills-prize',
      gameId:null
    };
  },
  mixins:[joinGame],
  mounted() {
    var _this = this;
    this.gameId = this.$route.params.gameid;
    this.changeLocale();
    this.getUserGameInfo();
    _this.$store.commit("updateSelected", {
      selected: this.$route.params.index
    });
    // this.connectToChat();
    this.getMahasangramScore();
  },

  methods: {
    updateContestInfo() {
      let _this = this;
      this.$http
        .get(
          constants.CONTESTSURL +
            this.$route.params.contestId +
            "?q=" +
            new Date().getTime()
        )
        .then(
          function(data) {
            _this.$store.commit('saveMahaSangramContestInfo',data.body);
            // this.$data.mahasangraminfo = data.body;
          },
          reponse => {}
        );
    },
    changeLocale(locale) {
      this.$i18n.locale = this.$cookie.get("lang") || "hi";
      if (this.$cookie.get("lang") == "ml") {
        document.body.classList.add("activeMultiLang");
      }
    },
    getBgColor(gamecolor) {
      return {
        background: `linear-gradient(100deg,${gamecolor}, ${gamecolor}, transparent)`
      };
    },
    hideMobile(username) {
      if (new RegExp(/^[0][6-9]\d{9}$|^[6-9]\d{9}$/g).test(username)) {
        var res = username.substring(7, username.length);
        var hiddenmobile = "*******" + res;
        return hiddenmobile;
      } else {
        return username;
      }
    },
    // addChat(){
    //   var obj = {
    //     'name': this.$data.mahasangraminfo.name,
    //     'contestReferenceId': this.$route.params.contestId,
    //     't': this.chatMsg,
    //     'profileImageUrl': this.$data.mahasangraminfo.profileImageUrl
    //   }
    //   this.$http.post(constants.MESSAGEPUSHURL,obj).then(function(data){
    //     this.chatMsg = '';
    //   },response => {

    //     this.$notify({
    //       group: "low-mcash",
    //       text: "Something went wrong"
    //     });
    //   })
    // },

    getMahasangramScore() {
      var a = new Date();
      this.$http
        .get(
          constants.CONTESTSURL +
            `${this.$route.params.contestId}/leaders?q=${a.getTime()}`
        )
        .then(
          function(data) {
            var _ = data.body.leaders;
            this.$data.contestScore = _;
          },
          response => {}
        );
    },

    getTime(timestamp) {
      var _time = new Date(timestamp).toLocaleTimeString();
      return _time;
    },

    openProfile(xAuthId) {
      this.xAuthId = xAuthId;
      try {
        JSBridgePlugin.showNativeProfileView(xAuthId);
        console.log(xAuthId);
      } catch (err) {
        console.log(err);
      }
    },

    // connectToChat(){
    //   var _this = this
    //   this.database.ref(`chat/${this.$route.params.contestId}`).on('child_added', function(data){
    //     //console.log(data.val())
    //     var doc =  data.val()
    //     _this.chats.push(doc)
    //     setTimeout(function(){
    //       var a = $('.chatlog-screen')
    //       a.animate({'scrollTop':a[0].scrollHeight})
    //     },10)
    //   })
    // },
    goBack() {
      this.$router.push(`/${this.gameId}`);
      // this.$router.go(-1);
    },
    getUserGameInfo() {
      // it is called recursively because after playing a game, domain changes,we wait for the games variable in store.
      const _this = this;
      if (_this.games[_this.$route.params.gameid] == null) {
        setTimeout(function() {
          _this.getUserGameInfo();
        }, 200);
      } else {
        const url =
          "contest_qualified_users/" +
          _this.$route.params.contestId +
          "/" +
          _this.$cookie.get("X-Auth-Id");
        if (
          _this.games[_this.$route.params.gameid].contests[
            _this.$route.params.contestId
            ].status == "LIVE"
        ) {
          _this.database
            .ref(url)
            .once("value")
            .then(function(snapshot) {
              if (snapshot.val() != null) {
                _this.$data.joined = true;
                // update contest info if user is joined
                _this.updateContestInfo();
              } else {
                _this.$data.joined = false;
              }
              _this.$data.loading = false;
            });
        } else {
          _this.$data.loading = true;
        }
      }
    },

    share() {
      var name = this.getSelectedGame.name;
      this.$http
        .get(
          constants.PAYBOARDTESTURL +
            `/PayBoard/rest/commons/fetchMiniappShareMessage?gameName=${name}`
        )
        .then(function(data) {
          const obj = {
            message: data.body,
            imageUrl: "",
            isStatusShare: true
          };
          try {
            JSBridgePlugin.share(JSON.stringify(obj));
          } catch (err) {}
        }).catch(err =>{
          console.log(err);
      });
    },

    clearSelected() {
      this.$data.selectedContest = null;
      this.$data.userInfo = null;
      this.$data.joined = false;
    },

    getModal: function() {
      showModal: true;
    },

    getYouTubeVideo: function() {
      var url =
        "https://www.youtube.com/embed/" +
        this.games[this.$route.params.gameid].videoId;
      return url;
    },

    playersFull() {
      if (
        this.selectedContest.currentPlayers < this.selectedContest.playersLimit
      ) {
        return true;
      } else {
        return false;
      }
    },

    openGame(game) {
      this.$data.selectedContest = game;

      var url =
        "contest_qualified_users/" +
        game.referenceId +
        "/" +
        this.$cookie.get("X-Auth-Id");

      var _this = this;
      if (game.status == "LIVE") {
        this.database
          .ref(url)
          .once("value")
          .then(function(snapshot) {
            if (snapshot.val() != null) {
              _this.$data.joined = true;
              _this.$http.get(constants.CONTESTSURL + game.referenceId).then(
                function(data) {
                  _this.$data.userInfo = data.body;
                },
                reponse => {}
              );
            } else {
              _this.$data.joined = false;
            }
            _this.$data.loading = false;
          });
      } else {
        _this.$data.loading = true;
      }
    },
    playGame() {
      console.log("Play Game ")
      if (window) {
        window.scrollTo(0, 0);
      }
      this.spinnerLoading = true;
      this.$http
        .post(constants.CONTESTSURL + `${this.$route.params.contestId}/player`)
        .then(
          function(data) {

            this.$router.push({name:'PlayGame', query:{gameUrl : data.body.game.url,contestId:this.$route.params.contestId}});

            /*if(this.$cookie.get("X-Auth-Id") == "8f14e45fceea167a5a36dedd4bea2543" || this.$cookie.get("X-Auth-Id") == "da4fb5c6e93e74d3df8527599fa62642" ||this.$cookie.get("X-Auth-Id") ==  "c81e728d9d4c2f636f067f89cc14862c"){
              // window.location.hash += _.substr(_.indexOf("?"),_.length)
              // window.location.pathname = _.substring(_.indexOf("1/")+1,_.lastIndexOf("/"))
              //JSBridgePlugin.openURL(_)
              window.open(_)
            }else{
              window.location.href = data.body.game.url
            } */

            //a.substring(a.indexOf("1/")+1,a.lastIndexOf("/"))
            // window.open(data.body.game.url)
            //location.href =  "http://tournament.money91.com/DontCrash/?uid=da4fb5c6e93e74d3df8527599fa62642&gameid=35"//data.body.game.url;
          },
          response => {
            //console.log(response);
            this.$notify({
              group: "low-mcash",
              text: data.data.failMessage
            });
            this.spinnerLoading = false;
          }
        );
    },

    getHomeData() {
      this.$http
        .get(constants.CONTESTSURL)
        .then(function(data) {}, response => {});
    },
    openCarouselGame(game) {
      for (var a in this.$data.games) {
        if (game.name == this.$data.games[a].name) {
          this.$data.selected = a;
          break;
        }
      }
      this.openGame(game.contestData);
    },
    getTimeString(time) {
      var _ = new Date(time);
      var min =
        _.getMinutes() < 10 ? "0" + _.getMinutes() : "" + _.getMinutes();
      return _.getHours() + ":" + min;
    },
    remainingTimeString(time) {
      var a = time;
      var b = new Date().getTime();

      var distance = a - b;

      var hrs = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var sec = Math.floor((distance % (1000 * 60)) / 1000);
      return hrs + " Hours " + min + " Minutes " + sec + " Secs";
    }
  },

  computed: {
    ...mapGetters(["games","getMahaSangramInfo"]),
    // getSelectedGame: function() {
    //   return this.$store.getters.games[this.$route.params.gamerefid];
    //   this.$forceUpdate();
    //   // return this.$store.getters.updateselectedgame
    // },
    getSelectedGame: function() {
      return this.games[this.$route.params.gameid];
    },

    getSelectedContestid: function() {
      if (
        this.$route.params.contestId in
        this.games[this.$route.params.gameid].contests
      )
        return this.games[this.$route.params.gameid].contests[
          this.$route.params.contestId
        ];
      else {
        this.$router.go(-1);
      }
    },

    getUserInfo: function() {
      return this.$store.getters.userInfo;
    }
  }
};
</script>
