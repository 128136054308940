<template>
  <div class="rewardsList">
    <div class="rewards_list">
      <span class="close">
        <i class="material-icons close_icon" @click="toggleRewards()">clear</i>
      </span>
      <ul class="gifted_user_list">

        <li v-for="(user, index) in users" :key="index"
            @click="selectedUser(index, user)"
            :class="{ active : activeUser == index }"
        >
          <div>
            <img v-if="user.profileImageUrl" :src="user.profileImageUrl" />
            <img v-else src="../../assets/images/avtar.png" />
            <span class="reward_count">
            {{index+1}}
          </span>
          </div>
        </li>

      </ul>
      <ul class="reward_type_list">
        <li class="mcash_gift_list">
          <div v-for="(gift, index) in McashGifts" :key="index">
            <span>
              <img
                v-if="gift.imageURL"
                :src="gift.imageURL"
                :class="{
                      active: selectedId && McashGifts[index].id === selectedId
                    }"
                @click="updateSelectedGift(index, gift.id)"
              />
            </span>
            <span class="reward_amount">{{gift.value}} MPoints</span>
          </div>
        </li>
        <li class="wallet_gift_list">
          <div v-for="(gift, index) in walletGifts" :key="index">
            <span>
              <img
                v-if="gift.imageURL"
                :src="gift.imageURL"
                :class="{
                      active: selectedId && walletGifts[index].id === selectedId
                    }"
                @click="updateSelectedGift(index, gift.id)"
              />
            </span>
            <span class="reward_amount">{{gift.value}} Wallet</span>
          </div>
        </li>
      </ul>
      <button type="button" class="btn-rewardBtn" @click="sendGifts()">Send Gifts</button>
    </div>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      :color="color"
    ></loading>
  </div>
</template>

<script>
  import apicaller from "../../utils/apicaller";
  import { mapState, mapGetters } from 'vuex'
  import { parsoDi } from '../../utils/groupsHelper'

  export default {
    name: "RewardsList",
    data() {
      return {
        roomTopic: [],
        toogleNotification: false,
        McashGifts: [],
        walletGifts: [],
        selectedId: 0,
        selectedType: 0,
        selectedValue: 0,
        chatRoomId: this.$route.params.id,
        users:[],
        isActive: false,
        activeUser:10,
        xauthid: this.$cookie.get('X-Auth-Id'),
        xauthToken: this.$cookie.get('X-Auth-Token'),
        selectedUserData:'',
        isLoading:false,
        color:"#FFF",
        // serverUrl:''

      };
    },
    computed: {
      ...mapState(['currentChatroom']),
      ...mapGetters(['serverUrl'])
    },
    created(){
      // this.serverUrl = 'https://groups.money91.com/';
      // if (window.location.hostname == 'localhost')
      //   this.serverUrl = 'http://localhost:5001/';
    },
    methods: {
      toggleRewards() {
        this.$store.commit("ACTIVEREWARD", false);
      },
      selectedUser(index, user) {

        this.activeUser = index
        this.selectedUserData = user
      },
      updateSelectedGift(index, id) {

        if (this.McashGifts.map(mg => mg.id).includes(id)) {
          this.$data.selectedId = this.McashGifts[index].id
          this.$data.selectedType = this.McashGifts[index].giftType
          this.$data.selectedValue = this.McashGifts[index].value
        }
        if (this.walletGifts.map(mg => mg.id).includes(id)) {
          this.$data.selectedId = this.walletGifts[index].id
          this.$data.selectedType = this.walletGifts[index].giftType
          this.$data.selectedValue = this.walletGifts[index].value
        }
        this.updateSelectedGift == true
      },

      async sendGifts() {
        let _this = this;
        this.isLoading = true;
        if(this.selectedUserData !== '' && this.selectedId !== 0) {
          try {
            _AI.refereshToken = async (res) => {
              if (res != "fail") {
                var obj = {
                  userWhoIsReceivingTip: this.selectedUserData.xauthid,
                  giftId: this.selectedId,
                  applicationId: 162,
                  xuthId : this.xauthid,
                  jwt_token: res
                }
                let data = parsoDi(obj)

                await _this.$http
                  .post(_this.serverUrl+'api/process-tip', {
                    ciphertext: data,
                    timestamp : new Date().getTime()
                  })
                  .then(
                    function(data) {
                      if (data.data.status) {
                        _this.toggleRewards()
                        _this.$toast.bottom("Tipping Successful");
                        _this.isLoading = false
                      } else {
                        _this.$toast.bottom("Tipping Unsuccessful");
                        _this.isLoading = false
                      }
                    }
                  )
              } else {
                _this.isLoading = false;
                _this.$toast.bottom('Something went wrong.')
              }
            };
            JSBridgePlugin.refreshTokens("_AI.refereshToken");
          } catch (err) {
            _this.isLoading = false
            console.log(err);
          }
        } else {
        _this.isLoading = false
          _this.$toast.bottom('Please select a user and gift.')
        }

      },

    },
    async mounted() {
      let _this = this;
      for (let index = 1; index < 8; index++) {
        if(
          this.currentChatroom &&
          this.currentChatroom[`seat${index}`] &&
          this.currentChatroom[`seat${index}`].xauthid != '' &&
          this.currentChatroom[`seat${index}`].xauthid != this.xauthid
        ) {
          let xauthid = this.currentChatroom[`seat${index}`].xauthid
          const userData = await this.$http.get(
            _this.serverUrl + 'api/getuserbyauth/' + xauthid
          )
          if (userData.data.status) {
            this.users.push(userData.data.user_data)
          }
        }
      }

      try {
        let data = apicaller("/rest/channels/getGifts", "get", null).then(
          data => {
            // console.log("tag", data);
            this.McashGifts = data.MCASH;
            this.walletGifts = data.WALLET;
          }
        );
      } catch (e) {
        // console.log(e);
      }
    }
  };
</script>
