<template>
  <div class="inviteFriendList">
    <div class="invite_friend_list">
      <i class="material-icons" @click="toggleInviteFriend()" style="float:right;">clear</i>
      <h3>Invite Friends</h3>
      <ul v-if="myfollowers">
        <li v-for="(follower, index) in myfollowers" :key="index">
          <img v-if="follower.image" :src="follower.image">
          <img v-else src="../../assets/images/avtar.png" >
          <span>{{follower.userName}}</span>
          <span class="invite_text"  @click="toggleNotification(follower)">Invite</span>
        </li>

      </ul>
    </div>



  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { chat91Firebase } from '../../main'
  export default {
    name: "InviteFriendList",
    data() {
      return {
        roomTopic: [],
        myfollowers:[],
        chat91FirebaseDatabase : this.$firebase.database(chat91Firebase)
      };
    },

    computed: {
      ...mapState(["user"])
    },

    methods: {
      toggleInviteFriend(){
        this.$store.commit("ACTIVEFRIENDLIST", false);
      },
      async toggleNotification(follower){
        let inviteData = {
          userWhoInvite : this.user.nickName || this.user.firstName,
          chatRoomId: this.$route.params.id,
        }
        // console.log('inviteData',inviteData)
        await this.chat91FirebaseDatabase.ref("invitations").child(follower.xauthid).push().set(inviteData);
        this.$store.commit("ACTIVEFRIENDLIST", false);
      },
    },

    async mounted() {
      let _this = this
      await _this.chat91FirebaseDatabase.ref("followers/"+_this.user.xauthid).once('value',(roomData) => {
        _this.myfollowers = roomData.val()
      });
    }

  };
</script>
